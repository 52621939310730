import React, { FC, ReactText, ComponentProps, useMemo } from 'react';
import { Translate } from '../../../localizations/Translate';
import * as utilsProj from 'utils.project/utils.project';
import i18n from '../../../localizations/i18n';
import { toast } from 'react-toastify';
import { store } from 'redux/store';
import { modalChangeDate } from 'redux/actions/Modals';
import {
    IWikiItemProps,
    cnWikiContextMenu
} from './ContextMenu.index';
import * as utils from 'utils/src/utils';
import { withComponentEnjector } from 'utils/src/ComponentInjector'
import ContextMenuComponent from 'uielements/src/ContextMenu/ContextMenu'
import { IconButton } from 'muicomponents/src/IconButton/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Popover } from 'muicomponents/src/Popover';
import { Button } from 'muicomponents/src/Button/Button';
import './ContextMenu.scss';
import { background } from 'uielements/src/Chart';
import { Typography } from 'muicomponents/src';
import { Link } from 'muicomponents/src/Link/Link';
import { MenuItem, MenuItemTypeMap, MenuList } from 'muicomponents/src/Menu';
import { CustomSettings, PageTypes } from 'utils/src';
import { FilesAdd } from 'blocks/DialogsV2/Files/FilesAdd';
import { useLazyQueryEx, useFileUploader } from 'utils/src/hooks'

const useFavoriteWikiItems = CustomSettings.useFavoriteWikiItems();

export const ContextMenuPresenter: FC<IWikiItemProps> = ({ injectionNodes, injectionComponents, onEdit, btnRender, removeItems, updateItemList, context, data, ...props }) => {

    const prepeiredContextActions = useMemo(() => {
        // если пользователь является модератором страницы ему доступно только редактирование согласно тикету https://tracker.yandex.ru/COMMON-11410
        // по умолчанию считаем, что для рендера компонента у пользователя есть хотя бы права модератора
        let actions: ({
            content: React.ReactNode;
        } | {
            content: JSX.Element;
            action: string;
        })[] = [
            {
                content: <Translate i18nKey={'pryaniky.wikilist.item.еdit'} />,
                action: 'edit'
            },
        ];
        // меню черновика
        if(data.isDraft) {
            actions.push({
                content: <Translate i18nKey={"pryaniky.modal.wikipage.delete"} />,
                action: 'delete'
            });
        }
        // для админа
        if(props.isAdmin) {
            actions = [
                ...injectionNodes.map((node) => ({ content: node })),
                ...injectionComponents.map((Component) => ({ content: <Component {...props} data={data} /> })),
                {
                    content: <Translate i18nKey={'pryaniky.wikilist.item.еdit'} />,
                    action: 'edit'
                },
                {
                    content: <Translate i18nKey={"pryaniky.modal.wikipage.delete"} />,
                    action: 'delete'
                },
                {
                    content: <Translate i18nKey={`pryaniky.modal.wikipage.${data.isArchived ? 'unarchive' : 'archive'}`} />,
                    action: 'archive'
                },
            ];
        }
        if(useFavoriteWikiItems) {
            actions.unshift({
                content: <Translate i18nKey={`pryaniky.modal.wikipage.${data.isFavorites ? 'unFavorites' : 'toFavorites'}`} />,
                action: 'favorites'
            });
        }
        if(data.type === PageTypes.file && !data.isDraft) {
            actions.unshift(
                {
    
                    content: <Translate i18nKey={'pryaniky.files.open'} />,
                    action: 'open',
                },
                {
                    content: <Translate i18nKey={'pryaniky.files.download'} />,
                    action: 'download',
                },
                {
                    content: <Translate i18nKey={'pryaniky.files.item.upload'} />,
                    action: 'upload',
                }
            );
            const pageActions = data.actions?.map(el => el.id);
            const openIsAllow = pageActions
                ? pageActions.includes('open') || pageActions.includes('file.open')
                : true
            if(!openIsAllow) {
                actions = actions.filter(el => (el as any).action !== 'open')
            }
            const downloadIsAllow = pageActions
                ? pageActions.includes('download') || pageActions.includes('file.download')
                : true
            if(!downloadIsAllow) {
                actions = actions.filter(el => (el as any).action !== 'download')
            }
        }
        return actions;
    }, [injectionNodes, injectionComponents, data.isDraft, data.type, props.isAdmin, data.actions]);

    const [popoverAnchor, setPopoverAnchor] = React.useState<{ top: number; left: number } | null>(null);

    const openPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        const rect = event.currentTarget.getBoundingClientRect();
        setPopoverAnchor({ top: rect.top + (rect.height / 2), left: rect.left + (rect.width / 2) });
    };

    const closePopover = () => {
        setPopoverAnchor(null);
        // setRemoveShure(false);
    }

    const clickPopoverHandle: ComponentProps<typeof Popover>['onClick'] = (event) => {
        event?.stopPropagation();
        closePopover();
    };

    const closePopoverHandle: ComponentProps<typeof Popover>['onClose'] = (event) => {
        closePopover();
    };

    const deleteWikiPage = (data: any) => {
        utilsProj.confirm({
            text: i18n.t("pryaniky.modal.wikipage.confirm.delete"),
            onConfirm: () =>
                utils.API.pages.deletePage(data.id)
                    .r
                    .then((response) => {
                        if (!response) toast.error(i18n.t('pryaniky.toast.error.server'));
                        else if (response.error_code === 0) {
                            toast.success(i18n.t("pryaniky.modal.wikipage.deleted"));
                            removeItems([data]);
                        }
                    })
        });

    }

    const copyWikiPage = (data: any) => {
        utilsProj.confirm({
            text: i18n.t("pryaniky.modal.wikipage.confirm.copy"),
            onConfirm: () =>
                utils.API.pages.copyPage(data.id)
                    .r
                    .then((response) => {
                        if (!response) toast.error(i18n.t('pryaniky.toast.error.server'));
                        else if (response.error_code === 0) {
                            toast.success(i18n.t("pryaniky.modal.wikipage.copied"));
                            window.location.href = response.data.url;
                           // removeItems([data]);
                        }
                    })
        });

    }
    const favoriteWikiPage = (data: any) => {
        utils.API.pages.favoritePage(data.id, !data.isFavorites)
            .r
            .then((response) => {
                if (!response) toast.error(i18n.t('pryaniky.toast.error.server'));
                else if (response.error_code === 0) {
                    toast.success(i18n.t(`pryaniky.modal.wikipage.${data.isFavorites ? 'unFavorited' : 'toFavorited'}`));
                    updateItemList(data, { ...data, isFavorites: !data.isFavorites })
                }
            })
    }

    const sendDataForEditPage = (isArchived?: boolean, fileData?: any[]) => {

        !fileData ? utilsProj.confirm({
            text: data.isArchived ? i18n.t("pryaniky.modal.wikipage.confirm.unarchieve.edit") : i18n.t("pryaniky.modal.wikipage.confirm.archieve.edit"),
            onConfirm: () => {
                const value = isArchived !== undefined ? isArchived : data.isArchived;
                return utils.API.pages.getById(data.id)
                    .r
                    .then((response) => {
                        if (!response) toast.error(i18n.t('pryaniky.toast.error.server'));
                        else if (response.error_code === 0) {
                            utils.API.pages.setSettings({
                                ...response.data,
                                type: data.type === "" ? "page" : data.type,
                                isArchived: value,
                                moderators: data.isInheritingRootRights ? [] : data.moderators,
                            })
                                .r
                                .then((response) => {

                                    if (!response) {
                                        toast.error(i18n.t('pryaniky.toast.error.server'));
                                    }
                                    else if (response.error_code === 0) {
                                        toast.success(i18n.t("pryaniky.modal.wikipage.sucсess"));

                                        updateItemList(data, { ...data, isArchived: value })

                                        // store.dispatch(modalChangeDate({ id: data.id || '', data: { ...data, isArchived: isArchived !== undefined ? isArchived : data.isArchived } }))
                                    }
                                });
                        }
                        else if (response.error_text) toast.error(response.error_text);
                    })
            },

        }) : utils.API.pages.getById(data.id)
            .r
            .then((response) => {
                console.log("fileData", fileData, fileData[0].name, fileData[0].id)
                if (!response) toast.error(i18n.t('pryaniky.toast.error.server'));
                else if (response.error_code === 0) {
                    utils.API.pages.setSettings({
                        ...response.data,
                        moderators: data.isInheritingRootRights ? [] : data.moderators,
                        file: fileData ? fileData[0] : data.file,
                        titleImageUrl: fileData ? fileData[0].previewUrl : data.titleImageUrl,
                        titleImageId: fileData ? fileData[0].id : data.titleImageId,
                        title: fileData ? fileData[0].name : data.title,
                    })
                        .r
                        .then((response) => {
                            if (!response) {
                                toast.error(i18n.t('pryaniky.toast.error.server'));
                            }
                            else if (response.error_code === 0) {
                                toast.success(i18n.t("pryaniky.modal.wikipage.sucсess"));

                                updateItemList(data, {
                                    ...data,
                                    file: fileData ? fileData[0] : data.file,
                                    titleImageUrl: fileData ? fileData[0].previewUrl : data.titleImageUrl,
                                    titleImageId: fileData ? fileData[0].id : data.titleImageId,
                                    title: fileData ? fileData[0].name : data.title,
                                })

                                store.dispatch(modalChangeDate({ id: data.id || '', data: { ...data, isArchived: isArchived !== undefined ? isArchived : data.isArchived } }))
                            }
                        });
                }
                else if (response.error_text) toast.error(response.error_text);
            });
    }

    const { openSelectFile, isLoading } = useFileUploader({
        uploadUrl: `/SetData/v4/files/uploadVersion/${data.file?.id}`,
        maxFilesCount: 1,
        uploadOnAdd: true,
        callbacks: {
            loadFinish(item, response) {

                if (response?.error_code === 3001) {
                    toast.error(i18n.t(`pryaniky.modal.wikipage.file.upload.3001`));
                } else if (response?.error_code !== 0) {
                    toast.error(response?.error_text);
                } else {
                    toast.success(i18n.t(`pryaniky.modal.wikipage.file.upload.success`));
                }
            },
            loadError(item) {

                if (item?.error?.error_text) {
                    toast.error(item?.error?.error_text);
                } else {
                    toast.error(i18n.t('pryaniky.upload_file.error.unknown_error'));
                }
            },
        }
    })

    const clickAction = (action: string) => {
        switch (action) {
            case 'edit':
                onEdit()
                break;
            case 'copy':
                copyWikiPage(data)
                break;
            case 'delete':
                deleteWikiPage(data)
                break;
            case 'archive':
                archivedPage()
                break;
            case 'favorites':
                favoriteWikiPage(data)
                break;
            case 'upload':
                // FilesAdd({ fileId: props.data.file.id, multiple: false }).then((fileData) => {
                //     // console.log("data fileData", fileData.files)
                //     sendDataForEditPage(props.data.isArchived, fileData.files)
                // }).catch(() => { })
                openSelectFile()
                break;
            default:
                break;
        }
    }

    const archivedPage = () => {
        sendDataForEditPage(!data.isArchived)
    }

    return (
        <>
            <IconButton
                style={{ background: "#fff" }}
                size="small"
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => openPopover(event)}
                color={"primary"}
            >
                <MoreVertIcon style={{ fontSize: "26px" }} />
            </IconButton>
            <Popover
                className={cnWikiContextMenu('')}
                BackdropComponent={React.Fragment}
                open={popoverAnchor ? true : false}
                // anchorEl={popoverAnchor}
                anchorReference="anchorPosition"
                anchorPosition={popoverAnchor || { top: 0, left: 0 }}
                onClick={clickPopoverHandle}
                onClose={closePopoverHandle}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center',
                }}
                PaperProps={{
                    elevation: 5,
                    sx: {
                        minWidth: 0,
                        display: 'flex',
                        flexDirection: 'column'
                    }
                }}
            >
                <MenuList >
                    {
                        prepeiredContextActions.map((act) => {
                            const action = act as Extract<typeof act, { action: any }>;
                            let prepairedActionProps: MenuItemTypeMap<ComponentProps<typeof Link>>['props'] = {};
                            if(action.action === "download" || action.action === "open") {
                                prepairedActionProps = {
                                    ...prepairedActionProps,
                                    href: data.file?.originalUrl,
                                    download: action.action === "download",
                                    component: Link,
                                    onClick: utils.noop
                                };
                            }
                            return (
                                <MenuItem
                                    sx={{
                                        padding: "12px 16px",
                                        "&.MuiLink-root": {
                                            color: "rgba(0, 0, 0, 0.87)",
                                        }
                                    }}
                                    className={cnWikiContextMenu('ContextActionButton')}
                                    onClick={() => clickAction(action.action)}
                                    {...prepairedActionProps}
                                >
                                    <Typography variant={"body2"}>{action.content}</Typography>
                                </MenuItem>
                            );
                        })
                    }
                </MenuList>
            </Popover>
        </>
    )
}


export const ContextMenu = withComponentEnjector(ContextMenuPresenter, ['wiki_list_item'])



