
import { cn } from '@bem-react/classname';

export interface IHeaderWikiWithFiltersProps {
    context: any;
    widget: any;
    hasRights: boolean;
    isAdmin: boolean;
    layoutWikilist?: any;
    prependItems: (item: any[]) => void
    // breadcrumbsMyWay?: any[]
    dashFolderInfo?:any[]
}


export const cnHeaderWikiWithFilters = cn('HeaderWikiWithFilters');
