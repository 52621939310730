import { CustomSettings } from 'utils/src';
import { generateWidget, generateColumn, widgets, IWidget, IColumn } from '../i.widgets';
import groupTabsIcons from '../icons/groupTabs';
import { wikilist } from './wikilist';

export const groupTabs = {
  feed: () => generateColumn({
    name: 'feed',
    iconFile: CustomSettings.useDefaultIconsInGroupTabs() ? {
      id: '111',
      action: '',
      name: 'defaultTimeline',
      originalUrl: groupTabsIcons.timeline,
      pdfPreviewUrl: null,
      previewUrl: groupTabsIcons.timeline,
      editUrl: null,
      size: 0,
      thumbUrl: ''
    } : undefined,
    items: [
      generateWidget<IColumn[]>({
        type: widgets.types.layout,
        data: [
          generateColumn({
            items: [
              generateWidget({
                type: widgets.types.timeline,
                settings: {
                  showFilter: true
                }
              }),
            ],
            styles: {
              width: '72%',
            },
          }),
          generateColumn({
            items: [
              generateWidget({
                type: widgets.types.users + '/participants',
              }),
              generateWidget({
                type: widgets.types.users + '/gadmins',
              }),
            ],
            styles: {
              width: '28%',
            },
            mobileHidden: true,
          }),
        ],
      }),
    ],
  }),
  users: () => generateColumn({
    name: 'colleagues',
    iconFile: CustomSettings.useDefaultIconsInGroupTabs() ? {
      id: '222',
      action: '',
      name: 'defaultColleagues',
      originalUrl: groupTabsIcons.colleagues,
      pdfPreviewUrl: null,
      previewUrl: groupTabsIcons.colleagues,
      editUrl: null,
      size: 0,
      thumbUrl: ''
    } : undefined,
    items: [
      generateWidget<IColumn[]>({
        type: widgets.types.layout,
        data: [
          generateColumn({
            items: [
              generateWidget({
                type: widgets.types.list + '/groupUsersSelector',
              }),
            ],
            styles: {
              width: '100%',
            },
          }),
        ],
      }),
    ],
  }),
  photo: () => generateColumn({
    name: 'photo',
    iconFile: CustomSettings.useDefaultIconsInGroupTabs() ? {
      id: '333',
      action: '',
      name: 'defaultPhoto',
      originalUrl: groupTabsIcons.photo,
      pdfPreviewUrl: null,
      previewUrl: groupTabsIcons.photo,
      editUrl: null,
      size: 0,
      thumbUrl: ''
    } : undefined,
    items: [
      generateWidget<IColumn[]>({
        type: widgets.types.layout,
        data: [
          generateColumn({
            items: [
              generateWidget({
                type: widgets.types.album,
                settings: {
                  photoView: true
                }
              }),
            ],
            styles: {
              width: '100%',
            },
          }),
        ],
      }),
    ],
  }),
  files: () => generateColumn({
    name: 'files',
    iconFile: CustomSettings.useDefaultIconsInGroupTabs() ? {
      id: '444',
      action: '',
      name: 'defaultAttachments',
      originalUrl: groupTabsIcons.attachments,
      pdfPreviewUrl: null,
      previewUrl: groupTabsIcons.attachments,
      editUrl: null,
      size: 0,
      thumbUrl: ''
    } : undefined,
    items: [
      generateWidget<IColumn[]>({
        type: widgets.types.layout,
        data: [
          generateColumn({
            items: [
              generateWidget({
                type: widgets.types.files,
              }),
            ],
            styles: {
              width: '100%',
            },
          }),
        ],
      }),
    ],
  }),
  wikilist: (...args: Parameters<typeof wikilist>) => generateColumn({
    name: 'wikilist',
    iconFile: CustomSettings.useDefaultIconsInGroupTabs() ? {
      id: '555',
      action: '',
      name: 'defaultPages',
      originalUrl: groupTabsIcons.pages,
      pdfPreviewUrl: null,
      previewUrl: groupTabsIcons.pages,
      editUrl: null,
      size: 0,
      thumbUrl: ''
    } : undefined,
    items: [
      wikilist(...args)
    ],
  }),
  imageGallery: (...args: Parameters<typeof wikilist>) => generateColumn({
    name: 'imageGallery',
    items: [
      wikilist(...args)
    ],
  }),
  videoGallery: (...args: Parameters<typeof wikilist>) => generateColumn({
    name: 'videoGallery',
    items: [
      wikilist(...args)
    ],
  }),
} as const;


export const userTabs = {
  about: () => generateColumn({
    name: 'about',
    items: [
      generateWidget({
        type: widgets.types.layout,
        data: [
          generateColumn({
            items: [
              // generateWidget({
              //   type: widgets.types.skills,
              // }),
              generateWidget({
                type: widgets.types.usetLmsStat,
              }),
              generateWidget({
                type: widgets.types.userAbout,
              }),
              generateWidget({
                type: widgets.types.userRating,
              }),
              generateWidget({
                type: widgets.types.ratings,
              }),
            ],
            styles: {
              width: '100%',
            },
          }),
        ],
      }),
    ],
  }),
  feed: () => generateColumn({
    name: 'feed',
    items: [
      generateWidget({
        type: widgets.types.layout,
        data: [
          generateColumn({
            items: [
              generateWidget({
                type: widgets.types.timeline,
                newsType: 'my',
              }),
            ],
            styles: {
              width: '100%',
            },
          }),
        ],
      }),
    ],
  }),
  subscriptions: () => generateColumn({
    name: 'subscriptions',
    items: [
      generateWidget({
        type: widgets.types.layout,
        data: [
          generateColumn({
            items: [
              generateWidget({
                // type: widgets.types.list + '/groupsSelector',
                type: widgets.types.userGroups,
              }),
            ],
            styles: {
              minWidth: '72%',
            },
          }),
          generateColumn({
            items: [
              generateWidget({
                type: widgets.types.filters,
              }),
              generateWidget({
                type: widgets.types.users + '/subscribers',
              }),
              generateWidget({
                type: widgets.types.users + '/subscriptions',
              }),
              generateWidget({
                type: widgets.types.users + '/hierarchyDown',
              }),
              generateWidget({
                type: widgets.types.users + '/hierarchyTop',
              }),
            ],
            styles: {
              width: '28%',
              // minWidth: '28%',
            },
            mobileHidden: true,
          }),
        ],
      }),
    ],
  }),
  reviews: () => generateColumn({
    name: 'reviews',
    items: [
      generateWidget({
        type: widgets.types.layout,
        data: [
          generateColumn({
            items: [
              generateWidget({
                type: widgets.types.timeline,
                newsType: 'feedback',
              }),
            ],
            styles: {
              width: '72%',
            },
          }),
          generateColumn({
            items: [
              generateWidget({
                type: widgets.types.ratings,
              }),
              // generateWidget({
              //   type: widgets.types.badges
              // })
            ],
            styles: {
              width: '28%',
            },
            mobileHidden: true,
          }),
        ],
      }),
    ],
  }),
  rewards: () => generateColumn({
    name: 'rewards',
    items: [
      generateWidget({
        type: widgets.types.layout,
        data: [
          generateColumn({
            items: [
              generateWidget({
                type: widgets.types.timeline,
                newsType: 'reward',
              }),
            ],
            styles: {
              width: '100%',
            },
          }),
        ],
      }),
    ],
  }),
  currencyTimeline: () => generateColumn({
    name: 'currencyTimeline',
    items: [
      generateWidget({
        type: widgets.types.layout,
        data: [
          generateColumn({
            items: [
              generateWidget({
                type: widgets.types.currencyTimeline
              }),
            ],
            styles: {
              width: '100%',
            },
          }),
        ],
      }),
    ],
  }),
}

export const userNewTabs = {
  about: () => generateColumn({
    name: 'about',
    items: [
      generateWidget({
        type: widgets.types.layout,
        data: [
          generateColumn({
            items: [
              generateWidget({
                type: widgets.types.layout,
                data: [
                  generateColumn({
                    items: [
                      generateWidget({
                        type: widgets.types.infoData,
                      })
                    ],
                    styles: {
                      width: '100%',
                    }
                  }),

                ]
              }),
              generateWidget({
                type: widgets.types.layout,
                data: [
                  generateColumn({
                    items: [
                      generateWidget({
                        type: widgets.types.userAbout,
                      })
                    ],
                    styles: {
                      width: '100%',
                    }
                  }),

                ]
              }),
              generateWidget({
                type: widgets.types.layout,
                data: [
                  generateColumn({
                    items: [
                      generateWidget({
                        type: widgets.types.badgesNew,
                      }),
                    ],
                    styles: {
                      width: '50%',
                    },
                  }),
                  generateColumn({
                    items: [
                      generateWidget({
                        type: widgets.types.ratingsNew,
                      }),
                    ],
                    styles: {
                      width: '50%',
                    },
                  }),
                ]
              }),
              generateWidget({
                type: widgets.types.layout,
                data: [
                  generateColumn({
                    items: [
                      generateWidget({
                        type: widgets.types.lastUserPost,
                      }),
                    ],
                    styles: {
                      width: '100%',
                    },
                  }),
                ]
              }),
              generateWidget({
                type: widgets.types.layout,
                data: [
                  generateColumn({
                    items: [
                      generateWidget({
                        type: widgets.types.skillsNew,
                      }),
                    ],
                    styles: {
                      width: '100%',
                    },
                  }),
                ]
              }),
              generateWidget({
                type: widgets.types.layout,
                data: [
                  generateColumn({
                    items: [
                      generateWidget({
                        type: widgets.types.userLmsStatNew,
                      }),
                    ],
                    styles: {
                      width: '100%',
                    },
                  }),
                ]
              }),
            ],
            styles: {
              width: '100%',
            },
          }),
        ],
      }),
    ],
  }),

  badges: () => generateColumn({
    name: 'badges',
    items: [
      generateWidget({
        type: widgets.types.layout,
        data: [
          generateColumn({
            items: [
              generateWidget({
                type: widgets.types.userBadges
              }),
            ],
            styles: {
              width: '100%',
            },
          }),
        ],
      }),
    ],
  }),

  ratings: () => generateColumn({
    name: 'ratings',
    items: [
      generateWidget({
        type: widgets.types.layout,
        data: [
          generateColumn({
            items: [
              generateWidget({
                type: widgets.types.userRatings
              }),
            ],
            styles: {
              width: '100%',
            },
          }),
        ],
      }),
    ],
  }),

  usergroups: () => generateColumn({
    name: 'usergroups',
    items: [
      generateWidget({
        type: widgets.types.layout,
        data: [
          generateColumn({
            items: [
              generateWidget({
                type: widgets.types.userGroupsNew
              }),
            ],
            styles: {
              width: '100%',
            },
          }),
        ],
      }),
    ],
  }),

  currencyTimeline: () => generateColumn({
    name: 'currencyTimeline',
    items: [
      generateWidget({
        type: widgets.types.layout,
        data: [
          generateColumn({
            items: [
              generateWidget({
                type: widgets.types.currencyTimeLineNew
              }),
            ],
            styles: {
              width: '100%',
            },
          }),
        ],
      }),
    ],
  }),

}