import { IEventsPropsType, mapStateToProps, mapDispatchToProps, TStateProps } from './Events.index'
import React, { FC, memo, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import './Events.scss'
import { ParticipantsBox } from './ParticipantsBox/ParticipantsBox'
import { removeMarkDownLink } from './Utils'
import { Calendar } from './Calendar/Calendar'
import { EventInfo } from './EventInfo/EventInfo'
import { EventAction } from './EventAction/EventAction'
import { withNewsContext } from 'News/contexts/news'
import TextRender from 'News/containers/TextRender'
import { ComponentInjector } from 'utils/src/ComponentInjector'
import NewsView from 'News/containers/NewsView'
import ContentLayout from 'News/components/ContentLayout'
import { SliderViewNew } from './SliderViewNew/SliderViewNew'
import MDNodeBulder from 'uielements/src/CommonmarkRender/MDNodeBulder'
import { noop } from 'lodash';

export const EventsPresenter: FC<IEventsPropsType & TStateProps> = ({ id, uevent, header, userData, text }) => {
    const {
        startDateTime,
        meeting,
        eventLocation,
        duration,
        eventNotGoers,
        eventMaybePar,
        eventParticipants,
        eventStatus,
        state,
        eventParticipantsCount,
        eventNotGoersCount,
        eventMaybePartCount,
        disallowParticipate = false,
        enableWaitList = false,
        maxParticipantsCount
    } = uevent;
    const eventPlaceText = useMemo(() => {
        const nb = new MDNodeBulder(text)
        return nb.getPlainText().join()
    }, [text]);
    return <NewsView className={'type-23'}>
        <div className="Events-Layout">
            <div className="Events-Header">
                <Calendar startDateTime={new Date(startDateTime)} />
                <EventInfo
                    startDateTime={new Date(startDateTime)}
                    duration={duration}
                    eventLocation={eventLocation}
                    avaliablePlaces={maxParticipantsCount
                        ? maxParticipantsCount - eventParticipantsCount < 0
                        ? 0
                        : maxParticipantsCount - eventParticipantsCount
                        : null}
                />
            </div>
            <div className="Events-ContentBox">
                <h3 className="Events-ContentTitle">{removeMarkDownLink(header)}</h3>

                <TextRender />

                <EventAction
                    disallowParticipate={disallowParticipate}
                    eventId={id}
                    eventState={state}
                    eventStatus={eventStatus}
                    startDateTime={new Date(startDateTime)}
                    eventTitle={removeMarkDownLink(header)}
                    eventText={eventPlaceText}
                    eventLocation={eventLocation}
                    duration={duration}
                    meeting={meeting}
                    enableWaitList = {enableWaitList}
                    allPlacesIsFull={maxParticipantsCount && maxParticipantsCount > 0 ? maxParticipantsCount === eventParticipantsCount : false}
                />
            </div>
        </div>
        <ContentLayout>
            {!disallowParticipate && <ParticipantsBox
                eventId={id}
                eventStatus={eventStatus}
                eventNotGoersCount={eventNotGoersCount}
                eventMaybePartCount={eventMaybePartCount}
                currentUser={userData.baseData}
                eventParticipantsCount={eventParticipantsCount}
                eventParticipants={eventParticipants}
                eventMaybePar={eventMaybePar}
                eventNotGoers={eventNotGoers}
                changeParticipants={noop} />}
        </ContentLayout>
    </NewsView>
}

export const Events = withNewsContext(connect(
    mapStateToProps,
    mapDispatchToProps
)(memo(EventsPresenter)));



export const initEvents = () => {
    ComponentInjector.getInstance().addNode('news_types', <Events />, 'events');
    ComponentInjector.getInstance().addNode('slider_news_types', <SliderViewNew />, 'events');
}