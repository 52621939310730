import React, { ReactText, FC, useState, useMemo, useCallback } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'localizations/Translate';
import './Item.scss';
import { DialogWikiEdit } from 'blocks/Dialogs/WikiCreateNew/WikiEdit/WikiEditNew';
import { GUID_EMPTY } from 'utils/src/constants.prn';
// import { Avatar } from 'uielements/src'
// import { Avatar } from 'muicomponents/src/Avatar/Avatar'
import {
    IWikiItemProps,
    IWikiItemState,
    mapStateToProps,
    mapDispatchToProps,
    cnItem
} from './Item.index';
import { ContextMenu } from '../../ContextMenu/ContextMenu'
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip';
// import file from '../file.svg'
import wikilist from '../wikilist.svg'
import page from '../page.svg'
import link from '../link.svg'
import { Box } from 'muicomponents/src/Box/Box'
import { WikiImageCardButton, WikiCardAvatar, WikiNameButton, WikiNameBox, defaultIconStyle, ActionBox, ActionContetBox, ItemContentBox } from '../style'
import { Chip } from 'muicomponents/src/Chip/Chip'
import MDRender from 'muicomponents/src/CommonmarkRender';
import { Article, InsertDriveFile, Folder, Link, FavoriteBorder, FavoriteOutlined, ChatBubbleOutline, ChatBubble, RemoveRedEye, Bookmark, BookmarkBorder, } from 'muicomponents/src/Icons';
import BaseRequests from 'utils/src/requests/requests.base';
import { pathToFilesIcons } from 'utils/src/constants.prn';
import { item } from 'uielements/src/PryanikyEditorV1/MentionSuggestions/MentionSuggestions';
import { Typography } from 'muicomponents/src';
import { likeWikiRequest } from "redux/actions/WikiList";
import likersModal from 'blocks/Dialogs/News/Likers/NewsLikers';
import { useDispatch, useSelector } from "react-redux";
import { formatDateSTD } from 'utils/src/utils.dates';
import { IconButton } from 'muicomponents/src/IconButton/IconButton'
import * as utilsProj from 'utils.project/utils.project';
import { CustomSettings, PageTypes } from 'utils/src';
import * as utils from 'utils/src/utils';
import { toast } from 'react-toastify';
import { getPageBaseType } from 'blocks/WikiListNew/utils/Wikilist.utils';
import { getProfileFileIcon } from '../Items.utils';

const useFavoriteWikiItems = CustomSettings.useFavoriteWikiItems();

export const WikiItemPresenter: FC<IWikiItemProps> = ({
    tag: TagName = 'div',
    className = '',
    data,
    changeItem,
    showControls,
    context,
    updateItemList,
    isEditable,
    parentFolderModerators,
    onItemLinkClick,
    ...props
}) => {
    const [showDialog, setShowDialog] = useState(false)
    const outLink = (data.url.indexOf('http') === 0 || data.url.indexOf('//') === 0) && !data.url.includes(window.location.hostname);
    const [liked, setLiked] = useState(data?.newsLiked)
    const [likeCountNumber, setLikeCountNumber] = useState(data?.newsLikeCount)


    const dispatch = useDispatch();

    const changeLike = (likeCount: number, liked: boolean) => {
        dispatch(likeWikiRequest(data?.newsId, likeCount, liked))
        // setLiked(!liked)
    }

    const changeLikeCount = () => {
        setLiked(!liked)
        setLikeCountNumber(!liked ? likeCountNumber + 1 : likeCountNumber - 1)
    }
    const onOpen = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
        likersModal({
            newsId: data?.newsId,
            // replyId
        })
    }, [data?.newsId])
    let href = outLink || data.url[0] === "/" ? data.url : "/" + data.url;

    if (!outLink && data.url.includes(window.location.hostname)) href = href.replace(href.substr(0, href.indexOf(window.location.hostname) + window.location.hostname.length), '');

    const pageIsEdeted = new Date(data.creationDate) < new Date(data.changeDate)

    const titleLength = data.title.slice(0, 20).indexOf(" ") === -1 ? 20 : data.title.length

    const noImg = data.titleImageId === GUID_EMPTY || data.titleImageId === null

    const pageBaseType = getPageBaseType(data.type);

    const fileType = pageBaseType === PageTypes.file ? data.title?.split(".") : []

    const Icon = pageBaseType === PageTypes.file ? getProfileFileIcon(fileType[fileType.length - 1] || data.icon) : null

    // const fileTypeIcon = data.icon === "jpg" || data.icon === "jpeg" ? jpg : data.icon === "doc" ? doc : data.icon === "docx" ? docx : data.icon === "png" ? png : data.icon === "pptx" ? pptx :
    //     data.icon === "rar" ? rar : data.icon === "txt" ? txt : data.icon === "xls" ? xls :
    //         data.icon === "xlsx" ? xlsx : data.icon === "zip" ? zip : data.icon === "avi" ? avi :
    //             data.icon === "mp4" ? mp4 : file;

    const pageType = pageBaseType === PageTypes.wikilist
        ? wikilist
        : pageBaseType === PageTypes.page
        ? page
        : pageBaseType === PageTypes.url
        && link;

    // console.log("data.title", data.title)
    const nameComponent = useMemo(() => {

        return <WikiNameButton
            className={cnItem(`WikiName ${pageBaseType === "url" ? "CustomStyle primaryColor3-text " : ""}`)}
            href={href}
            onClick={onItemLinkClick}
        >
            <Tooltip title={data.title.length > 40 ? data.title : ""}>
                <WikiNameBox sx={{ WebkitLineClamp: data.description.length === 0 ? 2 : 1 }}>
                    {/* <MDRender countLineCut={2} source= type={'textOnly'} /> */}
                    {data.title}
                </WikiNameBox>
            </Tooltip>

        </WikiNameButton>
    }, [pageBaseType, onItemLinkClick])

    const descComponent = useMemo(() => {

        return <Box>
            <Tooltip title={data.description.length > 20 ? data.description : ""}>
                <span>
                    <Typography
                        className={cnItem("CardArticleItem-Content-Description")}
                        variant='body2'
                        sx={{ color: "rgba(0, 0, 0, 0.87)" }}>

                        <MDRender countLineCut={1} source={data.description || ''} type={'inline'} />

                    </Typography>
                </span>
            </Tooltip>
        </Box>
    }, [])



    const componentLike = useMemo(() => {
        return <ActionContetBox
            className={cnItem("CardArticleItem-Content-ActionBox-Like primaryColor3-borderColor")}>
            {liked ? <FavoriteOutlined
                color='disabled'
                sx={{
                    fontSize: "16px",
                    marginRight: likeCountNumber > 0 ? "5px" : 0
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    changeLike(data?.newsLikeCount, liked)
                    changeLikeCount()

                }} /> :
                <FavoriteBorder
                    color='disabled'
                    sx={{
                        fontSize: "16px",
                        marginRight: "5px"
                    }}
                    onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        changeLike(data?.newsLikeCount, liked)
                        changeLikeCount()

                    }} />
            }
            <Typography
                sx={{ color: "rgba(0, 0, 0, 0.26)", opacity: likeCountNumber === 0 ? "0" : "1", paddingTop: "2px", cursor: "pointer" }}
                variant='caption'
                onClick={onOpen}
            >
                {likeCountNumber}
            </Typography>
        </ActionContetBox>
    }, [likeCountNumber, liked])


    const favoriteWikiPage = (data: any) => {
                utils.API.pages.favoritePage(data.id, !data.isFavorites)
                    .r
                    .then((response) => {
                        if (!response) toast.error(<Translate i18nKey={'pryaniky.toast.error.server'} />);
                        else if (response.error_code === 0) {
                            toast.success(<Translate i18nKey={`pryaniky.modal.wikipage.${data.isFavorites ? 'unFavorited' : 'toFavorited'}`} />);
                            updateItemList(data, { ...data, isFavorites: !data.isFavorites })
                        }
                    })
    }

    return (
        <Box sx={{ overflow: "hidden" }} className={cnItem({}, [className])}>

            {showControls || (isEditable && data.editable) ? <div className={cnItem("Actions_Hedaer")}>
                <ContextMenu
                    {...props}
                    data={data}
                    context={context}
                    updateItemList={updateItemList}
                    isAdmin={showControls || isEditable}
                    onEdit={() => DialogWikiEdit({
                        onConfirm: changeItem,
                        updateItemList,
                        context: context,
                        isShown: showDialog,
                        isAdmin: showControls,
                        onClose: () => setShowDialog(false),
                        parentFolderModerators: parentFolderModerators,
                        data: data,
                        id: data.id,
                    }).then(() => { }).catch(() => { })} />

            </div> : undefined}

            {useFavoriteWikiItems && <Box
                sx={{
                    height: "36px",
                    width: "36px",
                    background: '#fff', 
                    borderRadius: "50%", 
                    opacity: data.isFavorites ? 1 : 0
                }}
                className={cnItem(`Actions_Favorite`)}>
                <Tooltip title={<Translate i18nKey={`pryaniky.modal.wikipage.${data.isFavorites ? 'unFavorites' : 'toFavorites'}`} />}>
                    <span>
                        <IconButton
                            color={"primary"}
                            sx={{
                                padding: "5px",
                                "&:hover": {
                                    background: "#fff",
                                }
                            }}
                            onClick={() => favoriteWikiPage(data)}>

                            {data.isFavorites ? <Bookmark /> : <BookmarkBorder />}

                        </IconButton>
                    </span>
                </Tooltip>
            </Box>}

            {
                pageBaseType !== PageTypes.file
                ? <WikiImageCardButton // тип папка\страница\ссылка
                    className={cnItem(noImg ? 'Picture-BTNNoImg' : 'Picture-BTN')}
                    href={href}
                    onClick={onItemLinkClick}
                    >
                    <WikiCardAvatar
                        src={!noImg ? data.titleImageUrl : pageType}
                    />
                    {data.isDraft && <Chip
                        label={<Translate i18nKey={`pryaniky.wikilist.item.isDraft`} />}
                        className={cnItem("IsDraft")}
                        variant='filled'
                        sx={{
                            position: "absolute",
                            color: "#fff",
                            background: "rgba(0, 102, 204, 0.5)",
                            bottom: "12px",
                            left: "8px",
                            padding: "3px 10px",
                            height: "24px",
                            ".MuiChip-label": {
                                textTransform: "initial",
                                padding: 0
                            }
                        }}
                    />}
                    {data.isArchived && <Chip
                        label={<Translate i18nKey={`pryaniky.wikilist.item.isArchived`} />}
                        className={cnItem("IsArchived")}
                        variant='filled'
                        sx={{
                            position: "absolute",
                            color: "#fff",
                            background: "#757575",
                            bottom: "12px",
                            left: "8px",
                            padding: "3px 10px",
                            height: "24px",
                            ".MuiChip-label": {
                                textTransform: "initial",
                                padding: 0
                            }
                        }}
                    />}
                </WikiImageCardButton>
                : <WikiImageCardButton // тип файл
                    className={cnItem(noImg ? 'Picture-BTNNoImg' : 'Picture-BTN')}
                    href={href}
                    onClick={onItemLinkClick}
                    sx={{
                        width: "100%",
                        height: "188px",
                        backgroundImage: noImg ? `url(${Icon})` : `url(${data.titleImageUrl})`,
                        backgroundSize: noImg ? "90px" : "cover",
                        backgroundPosition: noImg ? "center" : "inherit",
                        backgroundRepeat: noImg ? "no-repeat" : "inherit",
                        backgroundColor: noImg ? "#f5f5f5" : "#fff",
                        "&:hover": {
                            backgroundImage: noImg ? `url(${Icon})!important` : `url(${data.titleImageUrl})!important`,
                            backgroundSize: noImg ? "90px!important" : "cover!important",
                            backgroundPosition: noImg ? "center!important" : "inherit!important",
                            backgroundRepeat: noImg ? "no-repeat!important" : "inherit!important",
                            backgroundColor: noImg ? "#f5f5f5!important" : "#fff!important",
                        }
                    }}
                    >
                        {data.isArchived && <Chip
                            label={<Translate i18nKey={`pryaniky.wikilist.item.isArchived`} />}
                            className={cnItem("IsArchived")}
                            variant='filled'
                            sx={{
                                position: "absolute",
                                color: "#fff",
                                background: "#757575",
                                bottom: "12px",
                                left: "8px",
                                padding: "3px 10px",
                                height: "24px",
                                ".MuiChip-label": {
                                    textTransform: "initial",
                                    padding: 0
                                }
                            }}
                        />}
                </WikiImageCardButton>
            }

            <ItemContentBox className={cnItem("Content")}>
                <Box className={cnItem('CreationDate')} sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                    {
                        pageBaseType === PageTypes.page
                        ? <Tooltip title={<Translate i18nKey='pryaniky.wikilist.item.page' />}>
                            <Article sx={{ ...defaultIconStyle, marginLeft: "-3px" }} />
                        </Tooltip>
                        : pageBaseType === PageTypes.file
                        ? <Tooltip title={<Translate i18nKey='pryaniky.wikilist.item.file' />}>
                            <InsertDriveFile sx={{ ...defaultIconStyle, marginLeft: "-4px" }} />
                        </Tooltip>
                        : pageBaseType === PageTypes.wikilist
                        ? <Tooltip title={<Translate i18nKey='pryaniky.wikilist.item.folder' />}>
                            <Folder sx={{ ...defaultIconStyle, marginLeft: "-1px" }} />
                        </Tooltip>
                        : <Tooltip title={<Translate i18nKey='pryaniky.wikilist.item.link' />}>
                            <Link sx={{ ...defaultIconStyle, marginLeft: "-1px" }} />
                        </Tooltip>
                    }
                    <Typography variant={"body2"} color="rgba(0, 0, 0, 0.38)">
                        <Translate i18nKey='pryaniky.wikilist.item.created.new' values={{
                            creationDate: formatDateSTD(data.creationDate, false),
                            changeDate: formatDateSTD(data.changeDate, false)
                        }} />
                    </Typography>

                </Box>

                {nameComponent}

                {descComponent}

                {
                    pageBaseType !== PageTypes.wikilist && <ActionBox
                        className={cnItem("CardArticleItem-Content-ActionBox")}
                    >
                        {componentLike}

                        {
                            (
                                pageBaseType !== "url"
                                && data.allowComments
                            )
                            && <ActionContetBox
                                className={cnItem("CardArticleItem-Content-ActionBox-Chat primaryColor3-borderColor")}
                            >
                                {
                                    data?.newsCommented
                                    ? <ChatBubble
                                        color={"disabled"}
                                        sx={{
                                            fontSize: "16px",
                                            marginRight: "5px"
                                        }}
                                    />
                                    : <ChatBubbleOutline
                                        color={"disabled"}
                                        sx={{
                                            fontSize: "16px",
                                            marginRight: "5px"
                                        }}
                                    />
                                }
                                <Typography
                                    sx={{ color: "rgba(0, 0, 0, 0.26)", opacity: data?.newsCommentsCount === 0 ? "0" : "1", paddingTop: "2px" }}
                                    variant='caption'
                                >
                                    {data?.newsCommentsCount}
                                </Typography>
                            </ActionContetBox>
                        }

                        <ActionContetBox
                            className={cnItem("CardArticleItem-Content-ActionBox-Chat primaryColor3-borderColor")} >
                            <RemoveRedEye
                                color={"disabled"}
                                sx={{
                                    fontSize: "16px",
                                    marginRight: "5px"
                                }}
                            />
                            <Typography
                                sx={{ color: "rgba(0, 0, 0, 0.26)", opacity: data?.newsViewedCount === 0 ? "0" : "1", paddingTop: "2px" }}
                                variant='caption'
                            >
                                {data?.newsViewedCount}
                            </Typography>
                        </ActionContetBox>
                    </ActionBox>
                }

            </ItemContentBox>
        </Box >
    )
}
export const Item = connect(
    mapStateToProps,
    mapDispatchToProps
)(WikiItemPresenter)

