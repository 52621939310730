import React, { FC, useEffect, useState } from 'react';
import * as utils from 'utils/src/utils';
import { useSelector } from 'react-redux';
import i18n from 'localizations/i18n';
import Autocomplete from 'uielements/src/MaterialElements/Autocomplete'
import { getAuthUser } from 'utils/src/CommonRedux/base/actions';
import { Box, Button, Paper, Typography } from 'muicomponents/src';
import Stack from '@mui/material/Stack';
import AddIcon from '@mui/icons-material/Add';
import { IconButton } from 'muicomponents/src/IconButton/IconButton';
import { AvatarsTooltipList } from 'muicomponents/src/AvatarsTooltipList/AvatarsTooltipList';
import { IAddFormProps, IUsersHierarchyWidgetProps, cnHierarchyWidgetNew } from './Widget_type_hierarchyNew.index';
import ClearIcon from '@mui/icons-material/Clear';
import { WidgetTypeSkeletonMUI } from 'blocks/Widget/_type/Widget_type_skeletonMUI';
import { Translate } from 'localization';
import { PaperBox } from 'blocks/InfoBlock/UserNew/Avatar/styled';
import { Avatar } from 'muicomponents/src/Avatar';
import noDataImage from 'assets/svg/NoData.svg'
import { UserMention } from 'uielements/src/UserMention/UserMention';
import MDRender from 'uielements/src/CommonmarkRender'
import "./Widget_type_hierarchyNew.scss"
import { Delete } from 'muicomponents/src/Icons'
import { BoxFlexGap16 } from "../UsersWidget/_type/UserListWithDescription/Styled"
import { UsersSuggester } from "muicomponents/src/Suggester/type/UsersSuggester"
import { SelectableObject } from 'utils/src/BaseTypes/selectableObject.types';
import CardMedia from '@mui/material/CardMedia';
import EmptyWidget from 'assets/svg/EmptyWidget.svg';

import * as utilsProj from 'utils.project/utils.project';

const AddForm: FC<IAddFormProps> = ({ added, add, newUser, setNewUser, setAdded, title, items }: IAddFormProps) => {

  // console.log("added", added)
  return <Box sx={{  width:"100%"}} >
    {added ?
      <form onSubmit={add}>
        <Box display='flex' alignItems='center'>
          <UsersSuggester
            multiple={false}
            noAdornment
            TextFieldProps={{
              label: <Translate i18nKey={'pryaniky.hierarchy.select_user'} />
            }}
            sx={{
              width: "90%",
              ".Mui-focused": {
                width: "90%"
              }
            }}
            value={newUser?.newUser || []}
            onChange={(event, newUser) => setNewUser({ newUser })}
            disablePortal={true}
            filterSelectedOptions
            filterOptions={(options, state) => {
              const selectedItemsIds = items.map(item => item.id)
              return options.filter(option => !selectedItemsIds.includes(option.id))
            }}
          />       
          <IconButton onClick={() => {
            newUser?.newUser ? add() : setAdded(false)
          }}
          // sx={{ flex: 2 }}
          >
            {newUser?.newUser ? <AddIcon /> : <ClearIcon />}
          </IconButton>

        </Box>
      </form>
      :
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography fontWeight='700' className={cnHierarchyWidgetNew("Title")} >{title}</Typography>
        <IconButton onClick={() => setAdded(true)} size='small' color='primary' >
          <AddIcon />
        </IconButton>
      </Box>}
  </Box>
}

export const HierarchyWidgetNew = (props: IUsersHierarchyWidgetProps) => {

  const {
    context,
    settings,
    tag: TagName = 'div' } = props

  const authUser: any = useSelector(getAuthUser)

  const headOrSub = settings?.headusers ? "headusers" : "subusers"
  const head = settings?.headusers;
  const hierarchyName = settings?.hierarchy.name

  const [items, setItems] = useState<any>([])
  const [title, setTitle] = useState(i18n.t('pryaniky.user.settings.hierarchy.' + (head ? 'Head' : 'Sub')))
  const [newUser, setNewUser] = useState<any>()
  const [added, setAdded] = useState(false)
  const [loading, setLoading] = useState(false)

  const getData = () => {
    const uId = context.uId || authUser.profileData.userId
    //utils.API.hierarchy.getHierarchyByUserForWidget(0, 20, { selectedHeadUser: uId, hierarchyName: settings?.hierarchy.name, catfilter: headOrSub }) - так было, параметры не те
    settings?.hierarchy &&
      setLoading(true)
    utils.API.hierarchy.getHierarchyByUserForWidget(0, 20, { selectedHeadUser: uId, hierarchyName, catfilter: headOrSub }) // { selectedHeadUser: uId, hierarchyName: this.props.settings?.hierarchy.name, catfilter: this.headOrSub }
      .r
      .then((d: any) => {
        if (utils.checkResponseStatus(d)) {
          const items = d.data.map((user: { [s: string]: any }) => {
            user.title = user.displayName;
            user.url = '/user/' + user.id;
            if (utils.isEmptyGuid(user.imgId)) user.imgUrl = user.userPhotoUrl;
            return user;
          });
          setItems(items)
          if (head) {
            setTitle(settings?.hierarchy.headname ? settings?.hierarchy.headname : i18n.t('pryaniky.hierarchy.head'))
          } else {
            setTitle(settings?.hierarchy.subname ? settings?.hierarchy.subname : i18n.t('pryaniky.hierarchy.bottom'))
          }
          setLoading(false)
        } else if (d.error_code === 21002) { // deleted
          setLoading(false)
        }
      });
  }

  useEffect(() => {
    getData()
  }, [])

  const add = () => {
    if (newUser && settings?.hierarchy.name) {
      const topId = head ? newUser.newUser.id : (context.uId ? context.uId || context.currentUserId : authUser.baseData.id)
      const bottomId = head ? (context.uId ? context.uId || context.currentUserId : authUser.baseData.id) : newUser.newUser.id
      utils.API.hierarchy.changeHierarchyAction(
        encodeURIComponent(settings?.hierarchy.name),// что бы слеши не ломали роутинг
        topId,
        bottomId,
        true
      )
      setItems([...items, { ...newUser.newUser, url: `/user/${newUser.newUser.id}` }])
      setAdded(false)
      setNewUser({})
    } else {
      setAdded(false)
      setNewUser({})
    }
  }

  const onDelete = (item: any) => {
    const topId = head ? item.id : context.uId || context.currentUserId;
    const bottomId = head ? context.uId || context.currentUserId : item.id;
    utilsProj.confirm({
      text: i18n.t("pryaniky.hierarchy.confirm.delete"),
      onConfirm: () => {
        utils.API.hierarchy.changeHierarchyAction(
          settings?.hierarchy.name,
          topId,
          bottomId,
          false
        )
        setItems(items.filter((val: any) => val.id !== item.id))

      }
    });

  }

  if (!settings || !hierarchyName) return null
  if (loading) return <WidgetTypeSkeletonMUI />
  if ((!items || !items.length) && !settings?.hideIfNoData && !authUser.baseData.isAdmin) return <TagName {...props} className={cnHierarchyWidgetNew({})}>
    <PaperBox sx={{ margin: 0 }}>
      <Typography fontWeight='700' className={cnHierarchyWidgetNew("Title")}>
        {title}
      </Typography>
      <CardMedia
        component="svg"
        className={cnHierarchyWidgetNew("EmptyList-Avatar")}
        sx={{
          width: '100%',
          height: "200px",
          // width: 'auto', 
          // height: '100%',
          marginBottom: "12px"
        }}
        image={EmptyWidget}
      />
      {/* <Avatar srcSet={noDataImage} variant='square' sx={{ width: 'auto', height: '100%' }} /> */}
      <Typography>
        <Translate i18nKey={'pryaniky.widgets.settings.type.birthdays.noData.text'} />
      </Typography>
    </PaperBox>
  </TagName>
  if ((!items || !items.length) && settings?.hideIfNoData && !authUser.baseData.isAdmin) return <TagName {...{ ...props, isHidden: items.length === 0 || !items.length }} className={cnHierarchyWidgetNew({})}></TagName>

  // console.log("settings", settings)
  // console.log("items", items)

  if (settings.hierarcheListView) {

    return <TagName {...props} className={cnHierarchyWidgetNew({})}>
      <Paper sx={{ padding: '24px' }}>
        <Box sx={{ display: "flex", flexDirection: "column", grid: "16px", gridGap: "16px" }}>
          <Stack direction='row' alignItems='center' justifyContent='space-between' height={added ? "66px" : "28px"}>
            {
              (authUser.baseData.isAdmin && hierarchyName) ?
                <AddForm added={added} add={add} newUser={newUser} setNewUser={setNewUser} items={items} setAdded={setAdded} title={title} />
                :
                <Typography fontWeight='700' className={cnHierarchyWidgetNew("Title")}>
                  {title}
                </Typography>
            }
          </Stack>
          {Array.isArray(items) && <Box
            className={cnHierarchyWidgetNew('Content')}
            sx={{ display: "flex", gap: "18px", flexDirection: "column" }}>
            {items.length !== 0 && items.map((item: any) => <Box className={cnHierarchyWidgetNew('Item')}>
              <Avatar sx={{
                width: "48px",
                height: "48px"
              }}
                srcSet={item.imgUrl !== undefined ? item.imgUrl : `https://pryaniky.com/Img/UserProfiles/Preview/96m96/${item.id}.png`}
                alt={item.displayName} />
              <Box className={cnHierarchyWidgetNew('Item-Info')} sx={{ display: "flex", gap: "8px", gridGap: "8px" }}>
                <Box sx={{ width: "90%" }}>
                  <UserMention
                    className={cnHierarchyWidgetNew('Item-Info-Name')}
                    id={item.id.toString()} name={item.title || item.displayName} />
                  <Typography variant={"body2"} sx={{
                    color: " rgba(0, 0, 0, 0.60)",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                  }}>{item.position}</Typography>

                </Box>
                {(authUser.baseData.isAdmin && hierarchyName) && <IconButton
                  size={"small"}
                  className={cnHierarchyWidgetNew('Delete')}
                  onClick={() => onDelete(item)}
                >
                  <Delete />
                </IconButton>}
              </Box>
            </Box>
            )}

          </Box>}
        </Box>
      </Paper>
    </TagName>
  }



  return <TagName {...props} className={cnHierarchyWidgetNew({})}>
    <Paper sx={{ padding: '16px' }}>
      <Box>
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          {
            (authUser.baseData.isAdmin && hierarchyName) ?
              <AddForm added={added} add={add} newUser={newUser} setNewUser={setNewUser} setAdded={setAdded} items={items} title={title} />
              :
              <Typography fontWeight='700' className={cnHierarchyWidgetNew("Title")}>
                {title}
              </Typography>
          }
        </Stack>


        {items.length !== 0
          &&
          <AvatarsTooltipList items={items}
            count={6}
            size={40}
            onDelete={(authUser.baseData.isAdmin && hierarchyName) ? onDelete : undefined}
          />
        }
      </Box>
    </Paper>
  </TagName>
}
