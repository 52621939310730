import * as React from 'react';
import * as utils from 'utils/src/utils';
import * as utilsProj from 'utils.project/utils.project';
import { connect } from 'react-redux';
import { mapDispatchToProps, IDispatchProps } from "redux/connector";

import { mapStateToProps as mapStateToPropsList, IListState, cnList, mapActionsToProps } from './../../List.index';
import { IListGroupsDefaultProps, IListTypeGroupsProps, avalibleFiltersGroups } from './List_type_groups.index';

import './List_type_groups.scss';
import { AbstractList } from '../../List';
import { IListResponseWithData } from 'utils/src/requests/models/api.base';
import { Header } from '../../Header/List-Header';
import { ListLite, Avatar, Button, Icon, Loading, HelpTooltip } from 'uielements/src';
import i18n from 'localizations/i18n';
import { Item } from '../..//Item/List-Item';
import { updateContext } from 'redux/actions/Widgets';
import { toast } from 'react-toastify';
import { DialogGroupCreate } from 'blocks/Dialogs/Group/Create/GroupCreate';
import { changeMeInGroup, getGroupActionText } from 'redux/sagas/Groups/utils'
import withLoadSpit from 'blocks/HOCs/Button/withLoadSpin'
import { userRoles } from 'utils/src/constants.prn';
import StubListPlaceholder from '../../../../assets/svg/listPlaceholder.svg';
import { Translate } from 'localizations/Translate';
import MDRender from 'uielements/src/CommonmarkRender';
import Tooltip from 'uielements/src/MaterialElements/Tooltip';

const SpinButton = withLoadSpit(Button)

export class ListGroupsPresenter extends AbstractList<IListTypeGroupsProps, IListState> {
  public static defaultProps: IListGroupsDefaultProps = {
    requestOptions: {
      search: '',
      extended: true,
      groupListFilter: 'all',
      uid: '',
    },
  };

  public type = 'groups';

  constructor(props: IListTypeGroupsProps) {
    super(props);
    this.prepareData = this.prepareData.bind(this);
  }

  public componentDidMount() {
    this.mounted = true;
    // const initialFilter = this.props.widget.groupTypes || 'all'
    // this.props.updateContext('common', {
    //   filter: {
    //     id: initialFilter,
    //     type: 'type',
    //     value: initialFilter,
    //   },
    // });
    this.getData();
  }

  public search = (text: string) => {
    const { toggle } = this.props;
    this.searchString = text;
    toggle({
      id: this.listId,
      data: {
        hideData: text.length !== 0,
        clearData: true,
        isFinished: false
      }
    });
    this.getData();
  };

  public getData = () => {
    const { requestOptions, context, edit } = this.props;
    const { clearData, isLoading } = this.props;
    const { clearDataFromList, getGroups, toggle } = this.props;
    const id = this.listId;
    if (edit || isLoading) return;
    const opts = Object.assign({}, requestOptions);

    Object.keys(context).forEach((cKey: string) => {
      if (avalibleFiltersGroups[cKey]) opts[avalibleFiltersGroups[cKey]] = context[cKey];
    });

    // if (context.filter) {
    //   if (context.filter.type === 'type') opts.groupListFilter = context.filter.value;
    // }
    // if (context.uId) {
    //   opts.uid = context.uId;
    //   opts.groupListFilter = 'my';
    // }

    if (this.searchString && this.searchString !== '') opts.search = this.searchString;
    toggle({
      id: this.listId,
      data: {
        isLoading: true,
        isFinished: false
      }
    });
    if (clearData) clearDataFromList({ id, data: null });
    getGroups.call(this, id, opts);
  };

  public componentDidUpdate(pp: IListTypeGroupsProps) {
    const { context: c, toggle, isLoading } = this.props;
    const { context: pc } = pp;
    if (
      Object.keys(avalibleFiltersGroups).filter((fk) => c[fk] !== pc[fk] ? true : false).length
    ) {
      toggle({
        id: this.listId,
        data: {
          hideData: true,
          clearData: true,
          isFinished: false,
        }
      });
      // this.getData()
      setTimeout(() => this.getData(), 10);
    }
  }

  public prepareData(el: any) {
    el.id = el.pkid;
    let groupBadge;
    if (el.isHidden) {
      groupBadge = {
        className: 'Item_isHidden',
        text: <Tooltip title={i18n.t('pryaniky.list.groups.isHidden')}>
          <span><Icon icon="eye" style={{ position: 'relative', left: -2.5 }} /></span>
        </Tooltip>,
      };
    } else if (el.visibilityType) {
      groupBadge = {
        className: `Item_visibilityType_${el.visibilityType}`,
        text:<Tooltip title={i18n.t('pryaniky.list.groups.visibilityType')}>
         <span><Icon icon="lock-alt" /></span> 
        </Tooltip>,
      };
    }
    el.view_data = {
      avatar: (
        <Button theme='unstyled' type="rlink" href={'/group/' + el.pkid} >
          <Avatar
            key={el.imgId48x48}
            imgId={el.imgId48x48}
            imgUrl={el.imgUrl48x48}
            size={56}
            name={el.name}
            badge={groupBadge}
          />
          {
            el.showGroupInSubnetworks &&
            <div className={('Subsystem-Badge')}>
              <Tooltip title={i18n.t('pryaniky.list.groups.Subnetworks')}>
                <span><Icon icon="chart-network" /></span>
              </Tooltip>
            </div>
          }
        </Button>
      ),
      info: [
        [
          <Button className="groupeList" noBorder noPadding noBackground type="rlink" href={'/group/' + el.pkid} children={el.name} />,
          <Button theme='unstyled' type="rlink" href={'/group/' + el.pkid} children={<MDRender type='inline' source={el.description || ''} />} />,
          <Button theme='unstyled' type="rlink" href={'/group/' + el.pkid + '/colleagues'}>
            <Translate i18nKey={'number of participants'} />: {el.membersCount}
          </Button>
        ]
      ]
    };
    const buttonText = i18n.t(getGroupActionText(el, this.props.uIsAdmin))//i18n.t('pryaniky.group.actions.join');
    // if (!this.props.uIsAdmin && el.visibilityType === 1)
    //   buttonText = i18n.t(
    //     'pryaniky.group.actions.' + (el.isRequestAccessSent ? 'requestSent' : 'sendRequest')
    //   );
    // if (el.isMeInGroup) buttonText = i18n.t('pryaniky.group.actions.leave');

    const showAction = this.props.uid === this.props.context.uId

    el.view_data.info[0].push(
      el.isOfficialGroup ? (
        <span className="Group-Label">
          {i18n.t('pryaniky.list.groups.official')}
          <Icon icon={'achievement'} />
        </span>
      ) : (
        (!el.disableChangeJoinStatus && showAction) ?
          <SpinButton
            isLoading={el.sendingRequest}
            spinnerSize={17}
            className={'List-Item_group-Button_action'}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
              // e.stopPropagation();
              this.joinAction(el);
            }}
            noPadding
            children={buttonText}
          /> :
          null
      )
    );
  }

  public joinAction = (el: any) => {
    if (el.isMeInGroup) {
      utilsProj.confirm({
        text: i18n.t('pryaniky.list.groups.actions.confirm.leave', { name: el.name }),
        onConfirm: () => this.joinOrLeave(el, false),
      });
    } else this.joinOrLeave(el, true);
  };

  public joinOrLeave = (el: { [s: string]: any }, _join: boolean) => {
    const newEl = { ...el, sendingRequest: true }
    this.prepareData(newEl);
    this.props.changeListItem({
      id: this.listId,
      data: {
        item: el.id,
        data: newEl,
      }
    });
    let { join, ...fields } = changeMeInGroup(el.visibilityType, el.isRequestAccessSent, el.isMeInGroup, this.props.uIsAdmin)

    utils.API.groups.join(el.id, this.props.uid, join).r.then((d: any) => {
      if (!d) toast.error(i18n.t('pryaniky.toast.error.server'));
      else if (d.error_code) toast.error(d.error_Message);
      else {
        const newEl = { ...el, ...fields, sendingRequest: false, actions: join ? el.actions.filter((v: string) => 'request' !== v) : el.actions }
        this.prepareData(newEl);
        toast.success(i18n.t('pryaniky.toast.list.groups.actions.join.success'));
        this.props.changeListItem({
          id: this.listId,
          data: {
            item: el.id,
            data: newEl,
          }
        });
      }
    });
  }

  public renderChildren = () => {
    const { data, isFinished, isLoading, hideData, uRoles, uid, context } = this.props;
    const isCurrentUser = uid === context.uId;
    return (
      <React.Fragment>
        {/* <GroupCreate
          data={{}}
          isShown={this.state.createDialog}
          onClose={this.toggleGroupCreateDialog}
        /> */}
        <Header
          className={'rounded-top'}
          type={'common'}
          search={{
            placeholder: i18n.t('pryaniky.list.groups.search'),
            onChange: this.search,
          }}
          actions={
            uRoles && !uRoles.includes(userRoles.guest) ?
              {
                options: [
                  {
                    title: i18n.t('pryaniky.list.groups.actions.create'),
                    buttonProps: {
                      onClick: this.toggleGroupCreateDialog,
                    },
                  },
                ],
              }
              : undefined
          }
        />
        <ListLite
          className={'rounded-bottom'}
          isFinished={isFinished}
          isLoading={isLoading}
          loadMore={!hideData ? this.getData : utils.noop}
        >
          {data.map(el => (
            <Item
              lId={this.listId}
              iId={el.toString()}
              type={'common'}
              key={el.toString()}
              data_key={el.toString()}
            />
          ))}
        </ListLite>

        {isFinished && data.length === 0 && <div className="stub">
          <img src={StubListPlaceholder} alt="" />
          <h1> {i18n.t('pryaniky.list.groups.emptyList.title')}</h1>
          <p>{i18n.t(isCurrentUser ? 'pryaniky.list.groups.emptyList.text' : 'pryaniky.list.groups.emptyList.other.text')}</p>
        </div>}
      </React.Fragment>
    );
  };

  public toggleGroupCreateDialog = () => DialogGroupCreate({}).then(() => {}).catch(() => { });
}

export const List = connect<any, any, any, any>(
  mapStateToPropsList,
  mapDispatchToProps(mapActionsToProps)
)(ListGroupsPresenter);
