import React, { ComponentType, ComponentProps, FC, memo, useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import { cnNewsSender, NNewsSender } from './Sender.index';
import './Sender.scss';
import {
    Divider,
    Box,
    DelayPublicationPicker,
    PostToGroup,
    Button,
    FilesUploader as FilesUploaderComp,
    FileDropZone as FileDropZoneComp
} from 'muicomponents/src';
import {
    ErrorOutline
} from 'muicomponents/src/Icons/Icons';
import { Translate } from 'localizations/Translate';
import { useFileUploader, mapFromAttachmentsList } from 'utils/src/useFileUploader';
import { API } from 'utils/src/utils';
import { PrepareErrorText } from 'News/utils/utils.validate';
import { useNewsSender } from './Sender.hooks';
import { NewsCreateContext } from '../Creator/Creator.constants';
import { StlyedPostNewsButton, StyledSenderBox } from './Sender.styled';
import { useEnvironmentContex } from 'utils/src/EnvironmentContex';
import { useDialogContext } from 'utils/src/DialogCreator';
import { DraftContext, DraftContextProps, DraftContextProvider } from 'muicomponents/src/DraftComponents/Editor/Editor'
import { getAppSettings } from 'utils/src/CommonRedux/base/selectors';
import { useDidUpdateEffect } from 'utils/src/hooks';
import { toast } from 'react-toastify';
import { isEqual } from 'lodash';
import { memoizedComponent } from '../utils.sender';

const FilesUploader = memoizedComponent(FilesUploaderComp);

const FileDropZone = memoizedComponent(FileDropZoneComp);

const ErrorPresenter: FC<{}> = ({

}) => {
    return (
        <div className={cnNewsSender('Error')}>
            <div className={'Icon'}>
                <ErrorOutline />
            </div>
            <div>
                <h4>
                    <Translate i18nKey={'pryaniky.news.create.error.title'} />
                </h4>
                {/* <p>
                    <Translate i18nKey={'pryaniky.news.create.error.description'} />
                </p> */}
            </div>
        </div>
    )
}


export const NewsSenderActions: FC<NNewsSender.Props> = ({ disableSend }) => {
    const { isMobileOS } = useEnvironmentContex();

    const { creatorId, groupId } = useContext(NewsCreateContext);

    const {
        group,
        publishAt,
        attachments,
        newstype,
        isValid,
        errors,
        isAdmin,
        newsCreatorChange,
        newsCreatorNewsChange,
        postNews,
        validateNews
    } = useNewsSender(creatorId);

    const senderBoxSx = useMemo<ComponentProps<typeof StyledSenderBox>['sx']>(() => ({
        flexDirection: isMobileOS ? 'column' : undefined
    }), [isMobileOS]);

    const selectorsSx = useMemo<ComponentProps<typeof DelayPublicationPicker>['ButtonProps']>(() => ({
        sx: {
            padding: '8px 22px',
            justifyContent: 'center'
        }
    }), []);

    const publishAtChange = useCallback<ComponentProps<typeof DelayPublicationPicker>['onChange']>((iso) => {
        newsCreatorNewsChange({
            publishAt: iso || ''
        });
    }, []);

    const propsChange = useCallback<ComponentProps<typeof PostToGroup>['onChange']>((group) => {
        newsCreatorNewsChange({
            group
        });
    }, []);

    return (
        <StyledSenderBox
            className={cnNewsSender('Actions')}
            sx={senderBoxSx}
        >
            {
                isAdmin &&
                <DelayPublicationPicker
                    value={publishAt || null}
                    onChange={publishAtChange}
                    ButtonProps={selectorsSx}
                />
            }
            {
                groupId === -1 &&
                <PostToGroup
                    typeNews={newstype?.toString()}
                    value={group}
                    onChange={propsChange}
                    ButtonProps={selectorsSx}
                />
            }
            <StlyedPostNewsButton
                id={'SENDNEWSBUTTON'}
                variant='contained'
                size='large'
                disabled={disableSend}
                onClick={postNews}
            >
                <Translate i18nKey='publicate' />
            </StlyedPostNewsButton>
        </StyledSenderBox>
    )
};


/**
 * component for fill base news data like post to group, post in setted time
 * @param param0 
 * @returns 
 */
const NewsSenderPresenter: FC<NNewsSender.Props> = ({
    // sendNews,
    className,
    children
}) => {

    const { creatorId } = useContext(NewsCreateContext);

    const {
        group,
        publishAt,
        attachments,
        newstype,
        isValid,
        errors,
        isAdmin,
        newsCreatorChange,
        newsCreatorNewsChange,
        postNews,
        validateNews,
        hideSender
    } = useNewsSender(creatorId);

    const { maxFileSizeLimit } = useSelector<any, { maxFileSizeLimit: number }>(getAppSettings);

    const {
        isLoading,
        progress,
        startUpload,
        files,
        onFileChange,
        dndRef,
        dragEntered,
        dndTriggerRef,
        removeFile,
        totalSize,
        inputAccept,
        uploadError,
        setFiles,
        getAttachmentsList,
    } = useFileUploader({
        // allowTypes: [],
        // TODO check types
        defaultAttachments: (attachments || []) as any,
        uploadOnAdd: true,
        maxFileSize: isAdmin ? undefined : (maxFileSizeLimit || undefined),
        callbacks: {
            loadFinish(item, response) {
                if (response?.error_code) {
                    switch (response?.error_code) {
                        case 4050:
                            toast.error(<Translate i18nKey={'pryaniky.file.size.error'} values={{
                                fileName: item.name,
                                maxSize: maxFileSizeLimit
                            }} />);
                            break;
                        case 4052:
                            toast.error(response?.error_text);
                            break;
                        default:
                            if (response?.error_text)
                                toast.error(response?.error_text);
                            break;
                    }
                }
            },
        }
    });

    // useDidUpdateEffect(() => {
    //     switch(uploadError?.error?.error_code) {
    //         case 4050:
    //             toast.error(<Translate i18nKey={'pryaniky.file.size.error'} values={{
    //                 fileName: uploadError.file?.name,
    //                 maxSize: maxFileSizeLimit
    //             }} />);
    //             break;
    //         case 4052:
    //                 toast.error(uploadError?.error?.error_text);
    //                 break;
    //         default:
    //             if(uploadError?.error?.error_text)
    //                 toast.error(uploadError?.error?.error_text);
    //             break;   
    //     }
    // }, [uploadError]);

    const [draftWork, setDraftWork] = useState(false)
    const draftContext = useMemo<DraftContextProps>(() => ({
        locker: setDraftWork,
        maxFileSizeLimit
    }), [setDraftWork])

    useEffect(() => {
        // if(files.reduce((rez, el) => !rez ? rez : (!el.response?.data[0] ? false : true), true)) {
        // }
    }, [files]);

    useEffect(() => {
        // TODO check types
        if (!attachments || !attachments.length) setFiles(mapFromAttachmentsList((attachments || []) as any));
    }, [attachments]);

    useEffect(() => {
        newsCreatorChange({ validFile: !isLoading });
        if (!isLoading) {
            // TODO check types
            newsCreatorNewsChange({ attachments: getAttachmentsList() as any });
        }
    }, [isLoading]);

    const { isDialog } = useDialogContext(['isDialog']);

    const FilesUploaderProps = useMemo<ComponentProps<typeof FilesUploader>>(() => ({
        selectButtonProps: {
            inputId: 'createNewsUploadFile',
            variant: 'outlined',
            children: <Translate i18nKey={'pryaniky.news.create.selectFile'} />
        },
        removeFile: (item) => {
            // if (item.response?.data?.[0].id) API.files.remove(item.response?.data?.[0].id);
            removeFile(item);
        }
    }), []);

    return (
        <DraftContextProvider value={draftContext}>
            <Box
                className={cnNewsSender({}, [className])}
            >
                {
                    !isValid &&
                    <ErrorPresenter />
                }
                {children}
                {
                    !hideSender &&
                    <>
                        <Divider />
                        <Box
                            ref={dndTriggerRef}
                            sx={{ position: 'relative' }}
                        >
                            <FilesUploader
                                {...FilesUploaderProps}
                                files={files}
                                onFileChange={onFileChange}
                                inputAccept={inputAccept}
                                error={Boolean(errors.attachments)}
                                helperText={
                                    // TODO check types in BaseNews.attachments
                                    Boolean(errors.attachments) && PrepareErrorText(errors.attachments as any)
                                }
                            />
                            <FileDropZone
                                ref={dndRef}
                                show={dragEntered}
                            />
                        </Box>
                        <Divider />
                        {!isDialog && <NewsSenderActions disableSend={draftWork} />}
                    </>
                }
            </Box>
        </DraftContextProvider>
    )
}

export const NewsSender = NewsSenderPresenter;
// export const NewsSender = connect(
//     mapNewsSenderStateToProps,
//     mapNewsSenderActionsToProps
// )(NewsSenderPresenter)