import { GET_QUERY, SET_ACTIVE_TAB, SET_FILTERS, SET_ADDITIONAL_FILTERS, CLEAR_ADDITIONAL_FILTERS } from './actions';
import { ISearchState, TAdditionalPageFilter, TAdditionalNewsFilter, TAdditionalFileFilter, TSearchValue } from './interfaces';
import { ISearchActions } from './actions.interfaces';
import { sub, format } from 'date-fns';
import { Translate } from 'localization';

export const defaultAdditionalPageFilter: TAdditionalPageFilter = {
  author: null,
  searchOnlyTitle: false,
  categoryType: { id: 'all',  label: Translate.t({ i18nKey: 'pryaniky.filter.wikilist.all' }), value: 'all' },
  materialType: { id: 'all', label: Translate.t({ i18nKey:'pryaniky.list.wiki.filter.all'}), value: 'all' },
  order: 'abc',
  sort: 'asc'
}

const getToday = () => {
  const todayDate = new Date().getDate()
  const todayMonth = new Date().getMonth()
  const todayYear = new Date().getFullYear()
  return new Date(todayYear, todayMonth, todayDate, 3, 0, 0, 0)
}

const today = new Date()
const defaultStartDate = sub(getToday(), { months: 6 })

// console.log("today", today.toISOString())
export const defaultAdditionalNewsFilter: TAdditionalNewsFilter = {
  author: null,
  type: undefined,
  types:[],
  startDate: defaultStartDate.toISOString(),
  endDate: today.toISOString()
}

export const defaultAdditionalFileFilter: TAdditionalFileFilter = {
  searchOnlyName: false
}

const defaultState: ISearchState = {
  query: '',
  activeTab: 'all',
  additionalFilters: {
    files: defaultAdditionalFileFilter,
    news: defaultAdditionalNewsFilter,
    pages: defaultAdditionalPageFilter,
  },
  filters: []
}

export const searchReducer = (state = defaultState, action: ISearchActions): ISearchState => {
  switch (action.type) {

    case GET_QUERY: {

      return {
        ...state,
        query: action.payload,
      }
    }

    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.payload
      }

    case SET_FILTERS:
      return {
        ...state,
        filters: action.payload
      }

    case SET_ADDITIONAL_FILTERS:
      return {
        ...state,
        additionalFilters: {
          ...state.additionalFilters,
          [action.payload.filterName]: {
            ...state.additionalFilters[action.payload.filterName as keyof typeof state.additionalFilters],
            ...action.payload.value
          }
        }
      }

    case CLEAR_ADDITIONAL_FILTERS:
      return {
        ...state,
        additionalFilters: {
          ...state.additionalFilters,
          [action.payload.filterName]:
            action.payload.filterName === 'pages'
            ? defaultAdditionalPageFilter
            : action.payload.filterName === 'news'
            ? defaultAdditionalNewsFilter
            : action.payload.filterName === 'files'
            ? defaultAdditionalFileFilter
            : {}
        }
      }

    default:
      return state;
  }
}
