import { includes } from 'lodash';
import * as utils from "utils/src/utils";
import pages from 'pageStructures';
import { v1 as uuid } from 'uuid';
import { IWidget, widgets, IColumn } from "i.widgets";
import { IResponseWithData } from "utils/src/requests/models/api.base";
import { IStateType as IState, IStateType } from 'redux/store';

import actions from 'redux/actionsTypes/Widgets';
import { prepareContextFromSettings, IdefaultWidegtsWidgets, IWidgetsTogglers } from "redux/reducers/Widgets";
import { toast } from "react-toastify";
import i18n from "localizations/i18n";
import { toggleIsEditable } from "utils/src/CommonRedux/base/actions";

import { CacheHelper } from 'utils/src/CacheHelper'
import { initStructureFromServer } from "utils/src/CommonRedux/LifeCycol/actions";
import {
    appendNewsList,
} from 'News/redux/actions'
import {
    normalizeNews
} from 'News/redux/saga/utils'
import { PageModelList, TPage } from 'utils/src';
import { newDesignCookieName, NewDesignCookieValue } from 'utils/src/PreviewParams';

export const preparePageName = (name: string) => name.split('.').join('/');


interface IDefaultPage {
    registerNewWidgets: boolean;
    id: string;
    type: string;
    title: string;
    url: string;
    layout?: any;
    // changeDate: string;
    // creationDate: string;
    enableTwoFactorAuth: boolean;
    context: null; // i don't know what is it
    isTemplate: boolean;
    collectionAlias: string | null;
    newsUid: string | null;
    isLiked: boolean;
    isEditable: boolean;
    isHideFooter: boolean;
}

const defaultPage: IDefaultPage = {
    registerNewWidgets: true,
    id: uuid(),
    type: '',
    title: '',
    url: '/empty',
    layout: null,
    enableTwoFactorAuth: false,
    context: null,
    isEditable: false,
    collectionAlias: null,
    newsUid: null,
    isLiked: false,
    isTemplate: false,
    isHideFooter: true
}

export const changeWidgetsViewType = (type?: string) => ({
    type: actions.CHANGE_WIDGETS_VIEW_TYPE,
    payload: type || 'full'
})

export const setDraggingElem = (id?: string) => ({
    type: actions.SET_DRAGGING_ELEM,
    payload: id
})

export const setData = (payload: any) => ({
    type: actions.SET_DATA,
    payload
})

export const setContext = (payload: any) => ({
    type: actions.SET_CONTEXT,
    payload
})

export const setWidgets = (payload: any) => ({
    type: actions.SET_WIDGETS,
    payload
})

export const addWidget = (payload: any) => ({
    type: actions.ADD_WIDGET,
    payload
})

export const removeWidget = (id: any) => ({
    type: actions.REMOVE_WIDGET,
    payload: { id }
})

export const updateWidget = (payload: IWidget) => ({
    type: actions.UPDATE_WIDGET,
    payload
})

export const dragWidget = (payload: IWidget) => ({
    type: actions.DRAG_WIDGET,
    payload
})

export const addTabToWidget = (payload: { wId: string, column: IColumn }) => ({
    type: actions.ADD_TAB_TO_WIDGET,
    payload
})

export const changeWidgetData = (payload: { wId: string, data: { [s: string]: any } }) => ({
    type: actions.CHANGE_WIDGET_DATA,
    payload
})

export const changeWidgetSettings = (payload: { wId: string, settings: { [s: string]: any } }) => ({
    type: actions.CHANGE_WIDGET_SETTINGS,
    payload
})

export interface IwidgetToggleInterface {
    type: typeof actions.TOGGLE,
    payload: {
        variable: keyof IWidgetsTogglers,
        value: boolean | undefined
    }
}

export const toggle = (variable: keyof IWidgetsTogglers, value?: boolean): IwidgetToggleInterface => ({
    type: actions.TOGGLE,
    payload: {
        variable,
        value
    }
})

export const toggleLoading = (value?: boolean): IwidgetToggleInterface => ({
    type: actions.TOGGLE,
    payload: {
        variable: 'loading',
        value
    }
})

export const setActivePage = (name: string) => ({
    type: actions.SET_ACTIVE_PAGE,
    payload: name
})

/**
 * @todo types
 * @param payload 
 * @returns 
 */
export const updateActivePage = (payload: Partial<TPage>) => ({
    type: actions.UPDATE_ACTIVE_PAGE,
    payload
});

// /**
//  * 
//  * @param payload structure
//  */
// export const setStructure = (structure: string): any => ({
//     type: actions.SET_STRUCTURE,
//     payload: {
//         structure,
//         types: widgets.types
//     }
// })

export function resurrectStructure(stateWidgets: IdefaultWidegtsWidgets, widget: IWidget<any>) {
    if (widget &&
        (
            widget.type === widgets.types.layout ||
            widget.type === `${widgets.types.layout}/horizontal` ||
            widget.type === widgets.types.tabs ||
            widget.type === widgets.types.tabsControl ||
            widget.type === widgets.types.grid
        )) {
        widget.data = (widget.data || []).map((col: IColumn) => {
            col.items = (col.items as string[]).map((id) => resurrectStructure(stateWidgets, utils.clone(stateWidgets[id])))
            return col;
        })
    }
    if (widget?.relations[0] === 'common') widget.relations.splice(0, 1);
    return widget;
}

/**
 *
 * @param payload structure
 */

const prepareStructureUrl = (structure: any, userId: string) => {
    if (structure.url.indexOf('userNew') === -1) return structure.url
    return `/user/${userId || ''}`
}
export const setStructure = (structure: any): any => {
    return (dispatch: any, getState: () => IState) => {
        structure = utils.clone(structure);
        structure.layout = resurrectStructure(getState().widgets.widgets, utils.clone(getState().widgets.widgets[structure.layout]));
        const userId = getState().store.authUid
        structure.registerNewWidgets = true;
        if (structure?.isInheritingRootRights) {
            structure.moderators = []
        };
        structure.url = prepareStructureUrl(structure, userId) // костыль для корректного сохранения структуры страницы с showMeNewDesign на сервере
        utils.API.pages.set(structure).r.then((d) => {
            if (utils.checkResponseStatus(d)) {
                // if save successed then update chache
                // need to create base pesponse object with data
                CacheHelper.set('structures', structure.url.substring(1), {
                    error_code: 0,
                    error_text: "OK",
                    data: utils.clone(structure)
                })
                    .then((value) => console.log(value))
                    .catch(e => console.warn('CacheHelper, structures:', e))
                toast.success(i18n.t('pryaniky.toasts.success.pageSaved'))
            } else {
                console.error('save page error', d)
            }
        });
    }
}

export const getStructureFromStore = (state: IState) => {
    const structureName = Object.keys(state.widgets.structures).reduce((acc: string | null, cur) => state.router.location.pathname.includes(cur) ? cur : acc, null);
    if (!structureName) {
        console.error('NOT FIND STRUCTURE NAME');
        return;
    }
    const structure = utils.clone(state.widgets.pages[state.widgets.structures[structureName]]);
    structure.layout = resurrectStructure(state.widgets.widgets, utils.clone(state.widgets.widgets[structure.layout]));
    return structure;
}

export const getActiveStructure = () => (dispatch: any, getState: () => IState) => getStructureFromStore(getState())

export const getActiveStructureWidgets = () => (dispatch: any, getState: () => IState) => {
    const structure = getStructureFromStore(getState());
    return structure && getWidgets(structure.layout)
}


export const setStructureFromWidget = (widgetId: string): any => ({
    type: actions.SAVE_STRUCTURE_FROM_WIDGET,
    payload: {
        id: widgetId,
        types: widgets.types
    }
})


export type updateContextPayloadType = { id: string, context: { [s: string]: any } }
export type updateContextActionType = { type: typeof actions.UPDATE_CONTEXT, payload: updateContextPayloadType }

export const updateContext = (id: string, context: { [s: string]: any }) => ({
    type: actions.UPDATE_CONTEXT,
    payload: {
        id,
        context
    }
})

export const removePropFromContext = (contextId: string, propName: string) => ({
    type: actions.REMOVE_PROP_FROM_CONTEXT,
    payload: {
        contextId,
        propName
    }
})

export const updateContexts = (payload: Array<{ [s: string]: any }>) => ({
    type: actions.UPDATE_CONTEXTS,
    payload
})

export const backup = (payload: 'make' | 'restore') => ({
    type: actions.BACKUP_PAGE,
    payload
});

/**
 * @deprecated не используется
 */
export const getWidget = (id: string): any => (dispatch: any, getState: () => IState) => getState().widgets.widgets[id];

const getWidgets = (widget: IWidget, parentWidgetId?: string) => {
    let rez: IWidget[] = [];
    if (!widget.relations) widget.relations = [parentWidgetId || 'common', widget.id];
    if (!parentWidgetId && widget.relations[0] !== 'common') widget.relations.splice(0, 0, 'common');
    if (parentWidgetId && widget.relations[0] !== parentWidgetId) widget.relations.splice(0, 0, parentWidgetId);
    if (widget.relations[1] !== widget.id) widget.relations.splice(1, 0, widget.id);
    rez.push(widget)
    if (
        widget.type === widgets.types.layout ||
        widget.type === widgets.types.layout + '/horizontal' ||
        widget.type === widgets.types.tabs ||
        widget.type === widgets.types.tabsControl ||
        widget.type === widgets.types.grid
    ) {
        (widget.data || []).forEach((column: IColumn) => {
            (column.items as IWidget[]).forEach((cwidget: IWidget<any>) => {
                const widgs = getWidgets(cwidget, widget.id)
                rez = [...rez, ...widgs];
            })
        });
    }
    return rez;
}

const setBaseSettings = (widget: IWidget) => {
    if (widget.type === widgets.types.timeline && widget.newsType) {
        return {
            newsTypes: widget.newsType,
        };
    } else if (widget.type === widgets.types.filters) {
        return {
            newsTypes: 'all',
        };
    }
    return {};
}

const widget_lite = (widget: IWidget<any>) => {
    if (
        widget.type === widgets.types.layout ||
        widget.type === widgets.types.layout + '/horizontal' ||
        widget.type === widgets.types.tabs ||
        widget.type === widgets.types.tabsControl ||
        widget.type === widgets.types.grid
    )
        (widget.data || []).forEach((col: IColumn) => { col.items = (col.items as IWidget<any>[]).map((wid: IWidget<any>): string => wid.id) })
}


const structureWork = (d: IResponseWithData<IDefaultPage>, pageName: string, params: any, dispatch: any, cb?: any, notFromChache: boolean = false) => {
    //if (false) {
    // d.error_code = 2007
    if (utils.checkResponseStatus(d) && !window.PRN_SERVICE.client_widegts) {
        let page_widgets: any = {};
        let widget_contexts: any = {};
        getWidgets(d.data.layout).forEach((widget: IWidget) => {
            page_widgets[widget.id] = widget;
            widget_contexts[widget.id] = {
                __parent: widget.relations[0],
                ...setBaseSettings(widget),
                ...prepareContextFromSettings(widget.settings || {})
            }
        });
        if (notFromChache) {
            const { currentContexts = {} } = params;
            delete params.currentContexts;
            widget_contexts = Object.keys(widget_contexts).reduce((a, id) => ({ ...a, [id]: { ...widget_contexts[id], ...(currentContexts[id] || {}) } }), {});
        }
        const page = utils.cloneObject(d.data);
        if (pageName !== page.url && (pageName.indexOf('user') === 0 || pageName.indexOf('group') === 0))
            page.url = pageName;
        page.layout = page.layout.id;
        const pages = { [page.id]: page };
        const structures = { [pageName]: page.id };
        Object.keys(page_widgets).forEach((wId) => widget_lite(page_widgets[wId]));
        dispatch(toggleIsEditable(d.data.isEditable));
        dispatch(updateContext('common', d.data.context || {}));
        dispatch(setData({ contexts: widget_contexts, widgets: page_widgets, pages, structures }));
                //новость страницы отправляется в новости. Что бы работаь с коментариями и прочим
                dispatch(appendNewsList({
                    ...normalizeNews([page.news]),
                    group: 'base_widget_pages'
                }))
        
        if (cb) cb();
    } else {
        if (d.error_code === 2007) {
            dispatch(setData(clientStructureError(pageName, 'forbidden2007')))
        } else {
            dispatch(setData(clientStructure(pageName, params)));
        }
        if (cb) cb();
    }
    dispatch(toggleLoading(false));
}

const revertUserIntoNew = (pageName: string) => {
    if (pageName.indexOf('userNew') === -1) return pageName
    const uid = pageName.split('/')[1]
    return 'user/' + (uid || '')
}
export const getStructure = (pageName: string, params: any = {}, cb?: any) => {
    pageName = preparePageName(pageName);
    let pageNameForRequest = revertUserIntoNew(pageName)
    let ignoreCache = false;
    return (dispatch: any, getState: () => IState) => {
        dispatch(initStructureFromServer(false));
        dispatch(setActivePage(pageNameForRequest));

        const cdForServerResponse = () => {
            dispatch(initStructureFromServer(true));
            if (cb) cb();
        };

        CacheHelper.get('structures', pageNameForRequest)
            .then((value) => {
                if (utils.checkResponseStatus(value) && !ignoreCache) {
                    if (value) structureWork(utils.clone(value), pageName, params, dispatch, cb)
                    //что бы показывалась только если нету кеша
                    else !params.noLoader && dispatch(toggleLoading(true));
                }
            })
            .catch(e => {
                //чили кеш ответил ошибкой
                !params.noLoader && dispatch(toggleLoading(true));
                console.warn('CacheHelper, structures:', e)
            })

        utils.API.pages.get(pageNameForRequest)
            .r
            .then((d: IResponseWithData<IDefaultPage>) => {
                if (utils.checkResponseStatus(d)) {
                    CacheHelper.set('structures', pageNameForRequest, utils.clone(d))
                        .then((value) => console.log(value))
                        .catch(e => console.warn('CacheHelper, structures:', e))
                }
                ignoreCache = true;
                structureWork(d, pageName, { ...params, currentContexts: getState().widgets.contexts }, dispatch, cdForServerResponse, true)

            })

    };
}




const generateClientStructure = (cpage: IDefaultPage, pageName: string) => {
    let page_widgets: any = {}
    let widget_contexts: any = {}
    getWidgets(cpage.layout).forEach((widget: IWidget) => {
        page_widgets[widget.id] = widget;
        widget_contexts[widget.id] = {
            __parent: widget.relations[0],
            ...setBaseSettings(widget),
            ...prepareContextFromSettings(widget.settings || {})
        }
    });
    const page = utils.cloneObject(cpage);
    page.layout = page.layout.id;
    const cpages = {
        [page.id]: page
    }
    const structures = {
        [pageName]: page.id
    }
    Object.keys(page_widgets).forEach((wId) => widget_lite(page_widgets[wId]))
    return { contexts: widget_contexts, widgets: page_widgets, pages: cpages, structures }
}

const clientStructureError = (pageName: string, errorPageName: string) => {
    const cpage = { ...defaultPage, url: '/' + pageName, layout: pages[errorPageName] ? pages[errorPageName]({}) : pages.common() }
    return generateClientStructure(cpage, pageName)
}
const clientStructure = (pageName: string, params: any) => {
    let name = pageName.split('/')[0];
    const newDesignCookieValue = utils.getCookie(newDesignCookieName) as NewDesignCookieValue;
    switch(name) {
        case 'user':
            if(newDesignCookieValue === 'alfa' || newDesignCookieValue === 'beta') {
                name += newDesignCookieValue;
            }
            break;
    }
    const cpage = { ...defaultPage, url: '/' + pageName, layout: pages[name] ? pages[name](params) : pages.common() }
    return generateClientStructure(cpage, pageName)
}

export const getClientStructure = (pageName: string, params: any) => (dispatch: any) => dispatch(setData(clientStructure(pageName, params)))








/**
 * widgets selectors
 */

export const getWidgetsStore = (state: IStateType) => state.widgets

export const getCurrentPage = (...args: Parameters<typeof getWidgetsStore>) => {
    const widgets = getWidgetsStore(...args);
    if(!widgets.active) return undefined;
    return widgets.pages[widgets.structures[widgets.active]] as TPage;
};

export const getPageTypeSettings = (page?: TPage | PageModelList) => page?.pageType;

export const getPageType = (page?: TPage | PageModelList) => getPageTypeSettings(page)?.type || page?.type;

export const getCurrentPageType = (...args: Parameters<typeof getWidgetsStore>) => getPageType(getCurrentPage(...args));

export const getCurrentPageTypeSettings = (...args: Parameters<typeof getWidgetsStore>) => getPageTypeSettings(getCurrentPage(...args));
