import React, { ReactText, FC, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'localizations/Translate';
import './ItemList.scss';
import { DialogWikiEdit } from 'blocks/Dialogs/WikiCreateNew/WikiEdit/WikiEditNew';
import { GUID_EMPTY } from 'utils/src/constants.prn';
import { Avatar } from 'muicomponents/src/Avatar/Avatar'
import {
    IWikiItemListProps,
    mapStateToProps,
    mapDispatchToProps,
    cnItem
} from './ItemList.index';
import { ContextMenu } from '../../ContextMenu/ContextMenu'
import { Tooltip } from 'muicomponents/src/Tooltip/Tooltip';
import wikilist from '../wikilist.svg'
import page from '../page.svg'
import link from '../link.svg'
import { Box } from 'muicomponents/src/Box/Box'
import { WikiImageListButton, WikiNameButton, WikiNameBox, defaultIconStyle } from '../style'
import { Chip } from 'muicomponents/src/Chip/Chip'
import { Typography } from 'muicomponents/src';
import { Article, InsertDriveFile, Folder, Link, Bookmark, BookmarkBorder, } from 'muicomponents/src/Icons';
import { formatDateSTD } from 'utils/src/utils.dates';
import MDRender from 'muicomponents/src/CommonmarkRender';
import { IconButton } from 'muicomponents/src/IconButton/IconButton'
import * as utilsProj from 'utils.project/utils.project';
import { CustomSettings, PageTypes } from 'utils/src';
import * as utils from 'utils/src/utils';
import { toast } from 'react-toastify';
import { getPageBaseType } from 'blocks/WikiListNew/utils/Wikilist.utils';
import { getProfileFileIcon } from '../Items.utils';

const useFavoriteWikiItems = CustomSettings.useFavoriteWikiItems();

export const WikiItemListPresenter: FC<IWikiItemListProps> = ({
    tag: TagName = 'Box',
    className = '',
    data,
    changeItem,
    showControls,
    context,
    updateItemList,
    removeItems,
    parentFolderModerators,
    isEditable,
    onItemLinkClick,
    ...props
}) => {
    const [showDialog, setShowDialog] = useState(false)
    const outLink = (data.url.indexOf('http') === 0 || data.url.indexOf('//') === 0) && !data.url.includes(window.location.hostname);
    let href = outLink || data.url[0] === "/" ? data.url : "/" + data.url;

    if (!outLink && data.url.includes(window.location.hostname)) href = href.replace(href.substr(0, href.indexOf(window.location.hostname) + window.location.hostname.length), '');

    const pageIsEdeted = new Date(data.creationDate) < new Date(data.changeDate)
    const titleLength = data.title.length
    const noImg = data.titleImageId === GUID_EMPTY || data.titleImageId === null;

    const pageBaseType = getPageBaseType(data.type);

    const fileType = pageBaseType === PageTypes.file ? data.title?.split(".") : []

    const Icon = pageBaseType === PageTypes.file ? getProfileFileIcon(fileType[fileType.length - 1] || data.icon) : null;

    const pageType = pageBaseType === PageTypes.wikilist
        ? wikilist
        : pageBaseType === PageTypes.page
        ? page
        : pageBaseType === PageTypes.url
        && link;

    const descComponent = useMemo(() => {

        return <Box>
            <Tooltip title={data.description.length > 100 ? data.description : ""}>
                <span>
                    <Typography
                        className={cnItem("CardArticleItem-Content-Description")}
                        variant='body2'
                        sx={{ color: "rgba(0, 0, 0, 0.87)" }}>

                        <MDRender countLineCut={1} source={data.description || ''} type={'inline'} />

                    </Typography>
                </span>
            </Tooltip>
        </Box>
    }, [])

    const favoriteWikiPage = (data: any) => {
        utils.API.pages.favoritePage(data.id, !data.isFavorites)
            .r
            .then((response) => {
                if (!response) toast.error(<Translate i18nKey={'pryaniky.toast.error.server'} />);
                else if (response.error_code === 0) {
                    toast.success(<Translate i18nKey={`pryaniky.modal.wikipage.${data.isFavorites ? 'unFavorited' : 'toFavorited'}`} />);
                    updateItemList(data, { ...data, isFavorites: !data.isFavorites })
                }
            })
    }

    return (
        <Box className={cnItem({}, [className])}>
            <Box className={cnItem("Content")}>
                {
                    pageBaseType !== PageTypes.file
                    ? <WikiImageListButton
                        className={cnItem(noImg ? 'Picture-BTNNoImg' : 'Picture-BTN')}
                        href={href}
                        onClick={onItemLinkClick}
                        >
                        <Avatar
                            src={!noImg ? data.titleImageUrl : pageType}
                            sx={{ width: 56, height: 56 }}
                        />
                    </WikiImageListButton>
                    : <WikiImageListButton
                            className={cnItem(noImg ? 'Picture-BTNNoImg' : 'Picture-BTN')}
                            href={href}
                            onClick={onItemLinkClick}
                        >
                        <Box sx={{
                            width: "56px",
                            height: "56px",
                            borderRadius: "50%",
                            backgroundImage: noImg ? `url(${Icon})` : `url(${data.titleImageUrl})`,
                            backgroundSize: noImg ? "35px" : "cover",
                            backgroundPosition: noImg ? "center" : "inherit",
                            backgroundRepeat: noImg ? "no-repeat" : "inherit",
                            backgroundColor: noImg ? "#f5f5f5" : "#fff",
                        }}></Box>
                    </WikiImageListButton>

                }
                <Box className={cnItem("Content-InfoBox")}>
                    <WikiNameButton
                        className={cnItem(`WikiName ${pageBaseType === "url" ? "CustomStyle primaryColor3-text " : ""}`)}
                        href={href}
                        onClick={onItemLinkClick}
                        sx={{ marginBottom: "0px" }}
                    >
                        {
                            data.title === '' && pageBaseType !== PageTypes.url ?
                                <Translate i18nKey={'pryaniky.wikilist.item.emptyName'} />
                                :
                                <Tooltip title={data.title.length > 100 ? data.title : ""}>
                                    <span>
                                        <WikiNameBox variant='body1'>{pageBaseType === "url" && data.title === '' ? href : data.title || ''}</WikiNameBox>
                                    </span>
                                </Tooltip>
                        }
                    </WikiNameButton>

                    {descComponent}

                    <Box className={cnItem('CreationDate')} sx={{ width: "214px", display: "flex", alignItems: "center", gap: "8px" }}>

                        {
                            pageBaseType === PageTypes.page
                            ? <Tooltip title={<Translate i18nKey='pryaniky.wikilist.item.page' />}>
                                <Article sx={{ ...defaultIconStyle, marginLeft: "-3px" }} />
                            </Tooltip>
                            : pageBaseType === PageTypes.file
                            ? <Tooltip title={<Translate i18nKey='pryaniky.wikilist.item.file' />}>
                                <InsertDriveFile sx={{ ...defaultIconStyle, marginLeft: "-4px" }} />
                            </Tooltip>
                            : pageBaseType === PageTypes.wikilist
                            ? <Tooltip title={<Translate i18nKey='pryaniky.wikilist.item.folder' />}>
                                <Folder sx={{ ...defaultIconStyle, marginLeft: "-1px" }} />
                            </Tooltip>
                            : <Tooltip title={<Translate i18nKey='pryaniky.wikilist.item.link' />}>
                                <Link sx={{ ...defaultIconStyle, marginLeft: "-1px" }} />
                            </Tooltip>
                        }
                        <Typography variant={"body2"} color="rgba(0, 0, 0, 0.38)">
                            <Translate i18nKey='pryaniky.wikilist.item.created.new' values={{
                                creationDate: formatDateSTD(data.creationDate, false),
                                changeDate: formatDateSTD(data.changeDate, false)
                            }} />
                        </Typography>

                    </Box>

                </Box>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Chip
                    label={data.isDraft ? <Translate i18nKey={`pryaniky.wikilist.item.isDraft`} /> : <Translate i18nKey={`pryaniky.wikilist.item.isArchived`} />}
                    className={cnItem("IsDraft")}
                    variant='filled'
                    sx={{
                        // position: "absolute",
                        opacity: (data.isDraft || data.isArchived) ? 1 : 0,
                        color: "#fff",
                        background: data.isDraft ? "rgba(0, 102, 204, 0.5)" : "#757575",
                        // bottom: "12px",
                        // left: "8px",
                        padding: "3px 10px",
                        marginRight: "16px",
                        height: "24px",
                        ".MuiChip-label": {
                            textTransform: "initial",
                            padding: 0
                        }
                    }}
                />
                {useFavoriteWikiItems && <Box
                    sx={{
                        height: "36px",
                        width: "36px",
                        marginRight: "16px",
                        opacity: data.isFavorites ? 1 : 0
                    }}
                    className={cnItem("Actions_Hedaer")}>
                    <Tooltip title={<Translate i18nKey={`pryaniky.modal.wikipage.${data.isFavorites ? 'unFavorites' : 'toFavorites'}`} />}>
                        <span>
                            <IconButton
                                color={"primary"}
                                sx={{
                                    background: '#fff',
                                    borderRadius: "50%",
                                    padding: "5px",
                                    "&:hover": {
                                        background: "#fff",
                                    }
                                }}
                                onClick={() => favoriteWikiPage(data)}>

                                {data.isFavorites ? <Bookmark /> : <BookmarkBorder />}

                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>}

                <Box sx={{
                    height: "36px",
                    width: "36px",
                    marginRight: "16px",
                    opacity: 0
                }} className={cnItem("Actions_Hedaer")}>
                    {showControls || (isEditable && data.editable) ? <ContextMenu
                        {...props}
                        data={data}
                        removeItems={removeItems}
                        updateItemList={updateItemList}
                        context={context}
                        isAdmin={showControls || isEditable}
                        onEdit={() => DialogWikiEdit({
                            onConfirm: changeItem,
                            updateItemList,
                            context: context,
                            isShown: showDialog,
                            isAdmin: showControls,
                            onClose: () => setShowDialog(false),
                            parentFolderModerators: parentFolderModerators,
                            data: data,
                            id: data.id
                        }).then(() => { }).catch(() => { })} /> : undefined}

                </Box>
            </Box>
        </Box>
    )
}
export const ItemList = connect(
    mapStateToProps,
    mapDispatchToProps
)(WikiItemListPresenter)
